export const getDisplayMedia = () => navigator.mediaDevices.getDisplayMedia({
    //@ts-expect-error -- Not supported in this version of typescript
    selfBrowserSurface: "include",
    preferCurrentTab: true,
    video: {
        displaySurface: "browser",
        frameRate: { ideal: 60, max: 60 },
        width: { ideal: 1920, max: 1920 },
        height: { ideal: 1080, max: 1080 },
    },
});
