import { cx } from "@emotion/css";
import type React from "react";

import { Spinner } from "../spinner/spinner.js";

import * as styles from "./button.styles";

export type ButtonVariant =
  | "contrast"
  | "secondary"
  | "subtle"
  | "complementary"
  | "critical"
  | "criticalSubtle"
  | "criticalSecondary"
  | "criticalMuted";

export type UseButtonProps<Extra> = {
  size?: "xs" | "sm" | "md" | "lg";
  variant?: ButtonVariant;
  align?: "left" | "center" | "right";
  isLoading?: boolean;
  iconOnly?: boolean;
  isRound?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  shouldFillContainer?: boolean;
  className?: string;
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: React.MouseEvent<any>) => void;
} & Extra;

export const useButton = <Extra,>({
  align = "center",
  variant = "contrast",
  isLoading,
  iconOnly,
  isRound,
  isSelected,
  isDisabled,
  shouldFillContainer,
  size = "md",
  onClick,
  children,
  className,
  ...rest
}: UseButtonProps<Extra>) => {
  return {
    ...rest,
    disabled: isDisabled,
    onClick: isDisabled ? undefined : onClick,
    className: cx(
      styles.button,
      styles[size],
      styles[variant],
      styles[align],
      {
        [styles.isRound]: isRound,
        [styles.iconOnly]: iconOnly,
        [styles.fillContainer]: shouldFillContainer,
        [styles.selected]: isSelected,
      },
      className
    ),
    children: (
      <>
        <span className={cx(styles.content, { [styles.loading]: isLoading })}>
          {children}
        </span>
        {isLoading ? <Spinner className={styles.spinner} /> : null}
      </>
    ),
  };
};
