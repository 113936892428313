var io = Object.defineProperty;
var so = (e, t, r) => t in e ? io(e, t, { enumerable: !0, configurable: !0, writable: !0, value: r }) : e[t] = r;
var D = (e, t, r) => so(e, typeof t != "symbol" ? t + "" : t, r);
var zt = Array.isArray, wr = Array.from, ao = Object.defineProperty, Ge = Object.getOwnPropertyDescriptor, rn = Object.getOwnPropertyDescriptors, nn = Object.prototype, lo = Array.prototype, Ht = Object.getPrototypeOf;
function co(e) {
  return typeof e == "function";
}
const Ke = () => {
};
function uo(e) {
  return typeof (e == null ? void 0 : e.then) == "function";
}
function fo(e) {
  for (var t = 0; t < e.length; t++)
    e[t]();
}
const le = 2, on = 4, ht = 8, Ft = 16, ue = 32, pt = 64, cr = 128, Ue = 256, Nt = 512, W = 1024, Ae = 2048, gt = 4096, ve = 8192, Qe = 16384, sn = 32768, mt = 65536, vo = 1 << 19, an = 1 << 20, Re = Symbol("$state"), ho = Symbol("legacy props"), po = Symbol("");
function ln(e) {
  return e === this.v;
}
function cn(e, t) {
  return e != e ? t == t : e !== t || e !== null && typeof e == "object" || typeof e == "function";
}
function un(e) {
  return !cn(e, this.v);
}
function go(e) {
  throw new Error("https://svelte.dev/e/effect_in_teardown");
}
function mo() {
  throw new Error("https://svelte.dev/e/effect_in_unowned_derived");
}
function _o(e) {
  throw new Error("https://svelte.dev/e/effect_orphan");
}
function wo() {
  throw new Error("https://svelte.dev/e/effect_update_depth_exceeded");
}
function yo(e) {
  throw new Error("https://svelte.dev/e/props_invalid_value");
}
function bo() {
  throw new Error("https://svelte.dev/e/state_descriptors_fixed");
}
function xo() {
  throw new Error("https://svelte.dev/e/state_prototype_fixed");
}
function Eo() {
  throw new Error("https://svelte.dev/e/state_unsafe_local_read");
}
function ko() {
  throw new Error("https://svelte.dev/e/state_unsafe_mutation");
}
let dn = !1;
function Y(e) {
  return {
    f: 0,
    // TODO ideally we could skip this altogether, but it causes type errors
    v: e,
    reactions: null,
    equals: ln,
    version: 0
  };
}
function ee(e) {
  return /* @__PURE__ */ Ro(Y(e));
}
// @__NO_SIDE_EFFECTS__
function fn(e, t = !1) {
  const r = Y(e);
  return t || (r.equals = un), r;
}
// @__NO_SIDE_EFFECTS__
function Ro(e) {
  return I !== null && I.f & le && (he === null ? Go([e]) : he.push(e)), e;
}
function L(e, t) {
  return I !== null && Zo() && I.f & (le | Ft) && // If the source was created locally within the current derived, then
  // we allow the mutation.
  (he === null || !he.includes(e)) && ko(), at(e, t);
}
function at(e, t) {
  return e.equals(t) || (e.v = t, e.version = Pn(), vn(e, Ae), C !== null && C.f & W && !(C.f & ue) && (U !== null && U.includes(e) ? (ge(C, Ae), jt(C)) : Le === null ? Jo([e]) : Le.push(e))), t;
}
function vn(e, t) {
  var r = e.reactions;
  if (r !== null)
    for (var n = r.length, o = 0; o < n; o++) {
      var i = r[o], s = i.f;
      s & Ae || (ge(i, t), s & (W | Ue) && (s & le ? vn(
        /** @type {Derived} */
        i,
        gt
      ) : jt(
        /** @type {Effect} */
        i
      )));
    }
}
const To = 1, So = 2, Co = 16, Do = 1, Io = 4, Po = 8, Mo = 16, Lo = 1, Ao = 2, No = 4, hn = 1, qo = 2, V = Symbol();
let Oo = !1;
function z(e, t = null, r) {
  if (typeof e != "object" || e === null || Re in e)
    return e;
  const n = Ht(e);
  if (n !== nn && n !== lo)
    return e;
  var o = /* @__PURE__ */ new Map(), i = zt(e), s = Y(0);
  i && o.set("length", Y(
    /** @type {any[]} */
    e.length
  ));
  var u;
  return new Proxy(
    /** @type {any} */
    e,
    {
      defineProperty(c, l, a) {
        (!("value" in a) || a.configurable === !1 || a.enumerable === !1 || a.writable === !1) && bo();
        var d = o.get(l);
        return d === void 0 ? (d = Y(a.value), o.set(l, d)) : L(d, z(a.value, u)), !0;
      },
      deleteProperty(c, l) {
        var a = o.get(l);
        if (a === void 0)
          l in c && o.set(l, Y(V));
        else {
          if (i && typeof l == "string") {
            var d = (
              /** @type {Source<number>} */
              o.get("length")
            ), f = Number(l);
            Number.isInteger(f) && f < d.v && L(d, f);
          }
          L(a, V), Dr(s);
        }
        return !0;
      },
      get(c, l, a) {
        var p;
        if (l === Re)
          return e;
        var d = o.get(l), f = l in c;
        if (d === void 0 && (!f || (p = Ge(c, l)) != null && p.writable) && (d = Y(z(f ? c[l] : V, u)), o.set(l, d)), d !== void 0) {
          var v = w(d);
          return v === V ? void 0 : v;
        }
        return Reflect.get(c, l, a);
      },
      getOwnPropertyDescriptor(c, l) {
        var a = Reflect.getOwnPropertyDescriptor(c, l);
        if (a && "value" in a) {
          var d = o.get(l);
          d && (a.value = w(d));
        } else if (a === void 0) {
          var f = o.get(l), v = f == null ? void 0 : f.v;
          if (f !== void 0 && v !== V)
            return {
              enumerable: !0,
              configurable: !0,
              value: v,
              writable: !0
            };
        }
        return a;
      },
      has(c, l) {
        var v;
        if (l === Re)
          return !0;
        var a = o.get(l), d = a !== void 0 && a.v !== V || Reflect.has(c, l);
        if (a !== void 0 || C !== null && (!d || (v = Ge(c, l)) != null && v.writable)) {
          a === void 0 && (a = Y(d ? z(c[l], u) : V), o.set(l, a));
          var f = w(a);
          if (f === V)
            return !1;
        }
        return d;
      },
      set(c, l, a, d) {
        var y;
        var f = o.get(l), v = l in c;
        if (i && l === "length")
          for (var p = a; p < /** @type {Source<number>} */
          f.v; p += 1) {
            var m = o.get(p + "");
            m !== void 0 ? L(m, V) : p in c && (m = Y(V), o.set(p + "", m));
          }
        f === void 0 ? (!v || (y = Ge(c, l)) != null && y.writable) && (f = Y(void 0), L(f, z(a, u)), o.set(l, f)) : (v = f.v !== V, L(f, z(a, u)));
        var g = Reflect.getOwnPropertyDescriptor(c, l);
        if (g != null && g.set && g.set.call(d, a), !v) {
          if (i && typeof l == "string") {
            var h = (
              /** @type {Source<number>} */
              o.get("length")
            ), _ = Number(l);
            Number.isInteger(_) && _ >= h.v && L(h, _ + 1);
          }
          Dr(s);
        }
        return !0;
      },
      ownKeys(c) {
        w(s);
        var l = Reflect.ownKeys(c).filter((f) => {
          var v = o.get(f);
          return v === void 0 || v.v !== V;
        });
        for (var [a, d] of o)
          d.v !== V && !(a in c) && l.push(a);
        return l;
      },
      setPrototypeOf() {
        xo();
      }
    }
  );
}
function Dr(e, t = 1) {
  L(e, e.v + t);
}
function Ir(e) {
  return e !== null && typeof e == "object" && Re in e ? e[Re] : e;
}
function zo(e, t) {
  return Object.is(Ir(e), Ir(t));
}
var Ye, pn, gn;
function Ho() {
  if (Ye === void 0) {
    Ye = window;
    var e = Element.prototype, t = Node.prototype;
    pn = Ge(t, "firstChild").get, gn = Ge(t, "nextSibling").get, e.__click = void 0, e.__className = "", e.__attributes = null, e.__styles = null, e.__e = void 0, Text.prototype.__t = void 0;
  }
}
function mn(e = "") {
  return document.createTextNode(e);
}
// @__NO_SIDE_EFFECTS__
function xe(e) {
  return pn.call(e);
}
// @__NO_SIDE_EFFECTS__
function Bt(e) {
  return gn.call(e);
}
function k(e, t) {
  return /* @__PURE__ */ xe(e);
}
function ze(e, t) {
  {
    var r = (
      /** @type {DocumentFragment} */
      /* @__PURE__ */ xe(
        /** @type {Node} */
        e
      )
    );
    return r instanceof Comment && r.data === "" ? /* @__PURE__ */ Bt(r) : r;
  }
}
function O(e, t = 1, r = !1) {
  let n = e;
  for (; t--; )
    n = /** @type {TemplateNode} */
    /* @__PURE__ */ Bt(n);
  return n;
}
function Fo(e) {
  e.textContent = "";
}
// @__NO_SIDE_EFFECTS__
function ke(e) {
  var t = le | Ae;
  C === null ? t |= Ue : C.f |= an;
  var r = I !== null && I.f & le ? (
    /** @type {Derived} */
    I
  ) : null;
  const n = {
    children: null,
    ctx: A,
    deps: null,
    equals: ln,
    f: t,
    fn: e,
    reactions: null,
    v: (
      /** @type {V} */
      null
    ),
    version: 0,
    parent: r ?? C
  };
  return r !== null && (r.children ?? (r.children = [])).push(n), n;
}
function _n(e) {
  var t = e.children;
  if (t !== null) {
    e.children = null;
    for (var r = 0; r < t.length; r += 1) {
      var n = t[r];
      n.f & le ? yr(
        /** @type {Derived} */
        n
      ) : Se(
        /** @type {Effect} */
        n
      );
    }
  }
}
function Bo(e) {
  for (var t = e.parent; t !== null; ) {
    if (!(t.f & le))
      return (
        /** @type {Effect} */
        t
      );
    t = t.parent;
  }
  return null;
}
function wn(e) {
  var t, r = C;
  X(Bo(e));
  try {
    _n(e), t = Mn(e);
  } finally {
    X(r);
  }
  return t;
}
function yn(e) {
  var t = wn(e), r = (We || e.f & Ue) && e.deps !== null ? gt : W;
  ge(e, r), e.equals(t) || (e.v = t, e.version = Pn());
}
function yr(e) {
  _n(e), dt(e, 0), ge(e, Qe), e.v = e.children = e.deps = e.ctx = e.reactions = null;
}
function Vo(e) {
  C === null && I === null && _o(), I !== null && I.f & Ue && mo(), kr && go();
}
function Uo(e, t) {
  var r = t.last;
  r === null ? t.last = t.first = e : (r.next = e, e.prev = r, t.last = e);
}
function $e(e, t, r, n = !0) {
  var o = (e & pt) !== 0, i = C, s = {
    ctx: A,
    deps: null,
    deriveds: null,
    nodes_start: null,
    nodes_end: null,
    f: e | Ae,
    first: null,
    fn: t,
    last: null,
    next: null,
    parent: o ? null : i,
    prev: null,
    teardown: null,
    transitions: null,
    version: 0
  };
  if (r) {
    var u = Je;
    try {
      Pr(!0), Ut(s), s.f |= sn;
    } catch (a) {
      throw Se(s), a;
    } finally {
      Pr(u);
    }
  } else t !== null && jt(s);
  var c = r && s.deps === null && s.first === null && s.nodes_start === null && s.teardown === null && (s.f & an) === 0;
  if (!c && !o && n && (i !== null && Uo(s, i), I !== null && I.f & le)) {
    var l = (
      /** @type {Derived} */
      I
    );
    (l.children ?? (l.children = [])).push(s);
  }
  return s;
}
function jo(e) {
  const t = $e(ht, null, !1);
  return ge(t, W), t.teardown = e, t;
}
function br(e) {
  Vo();
  var t = C !== null && (C.f & ue) !== 0 && A !== null && !A.m;
  if (t) {
    var r = (
      /** @type {ComponentContext} */
      A
    );
    (r.e ?? (r.e = [])).push({
      fn: e,
      effect: C,
      reaction: I
    });
  } else {
    var n = et(e);
    return n;
  }
}
function Ko(e) {
  const t = $e(pt, e, !0);
  return (r = {}) => new Promise((n) => {
    r.outro ? Be(t, () => {
      Se(t), n(void 0);
    }) : (Se(t), n(void 0));
  });
}
function et(e) {
  return $e(on, e, !1);
}
function bn(e) {
  return $e(ht, e, !0);
}
function ce(e) {
  return _t(e);
}
function _t(e, t = 0) {
  return $e(ht | Ft | t, e, !0);
}
function Te(e, t = !0) {
  return $e(ht | ue, e, !0, t);
}
function xn(e) {
  var t = e.teardown;
  if (t !== null) {
    const r = kr, n = I;
    Mr(!0), oe(null);
    try {
      t.call(null);
    } finally {
      Mr(r), oe(n);
    }
  }
}
function En(e) {
  var t = e.deriveds;
  if (t !== null) {
    e.deriveds = null;
    for (var r = 0; r < t.length; r += 1)
      yr(t[r]);
  }
}
function kn(e, t = !1) {
  var r = e.first;
  for (e.first = e.last = null; r !== null; ) {
    var n = r.next;
    Se(r, t), r = n;
  }
}
function Yo(e) {
  for (var t = e.first; t !== null; ) {
    var r = t.next;
    t.f & ue || Se(t), t = r;
  }
}
function Se(e, t = !0) {
  var r = !1;
  if ((t || e.f & vo) && e.nodes_start !== null) {
    for (var n = e.nodes_start, o = e.nodes_end; n !== null; ) {
      var i = n === o ? null : (
        /** @type {TemplateNode} */
        /* @__PURE__ */ Bt(n)
      );
      n.remove(), n = i;
    }
    r = !0;
  }
  kn(e, t && !r), En(e), dt(e, 0), ge(e, Qe);
  var s = e.transitions;
  if (s !== null)
    for (const c of s)
      c.stop();
  xn(e);
  var u = e.parent;
  u !== null && u.first !== null && Rn(e), e.next = e.prev = e.teardown = e.ctx = e.deps = e.fn = e.nodes_start = e.nodes_end = null;
}
function Rn(e) {
  var t = e.parent, r = e.prev, n = e.next;
  r !== null && (r.next = n), n !== null && (n.prev = r), t !== null && (t.first === e && (t.first = n), t.last === e && (t.last = r));
}
function Be(e, t) {
  var r = [];
  xr(e, r, !0), Tn(r, () => {
    Se(e), t && t();
  });
}
function Tn(e, t) {
  var r = e.length;
  if (r > 0) {
    var n = () => --r || t();
    for (var o of e)
      o.out(n);
  } else
    t();
}
function xr(e, t, r) {
  if (!(e.f & ve)) {
    if (e.f ^= ve, e.transitions !== null)
      for (const s of e.transitions)
        (s.is_global || r) && t.push(s);
    for (var n = e.first; n !== null; ) {
      var o = n.next, i = (n.f & mt) !== 0 || (n.f & ue) !== 0;
      xr(n, t, i ? r : !1), n = o;
    }
  }
}
function Ve(e) {
  Sn(e, !0);
}
function Sn(e, t) {
  if (e.f & ve) {
    yt(e) && Ut(e), e.f ^= ve;
    for (var r = e.first; r !== null; ) {
      var n = r.next, o = (r.f & mt) !== 0 || (r.f & ue) !== 0;
      Sn(r, o ? t : !1), r = n;
    }
    if (e.transitions !== null)
      for (const i of e.transitions)
        (i.is_global || t) && i.in();
  }
}
let qt = !1, ur = [];
function Cn() {
  qt = !1;
  const e = ur.slice();
  ur = [], fo(e);
}
function wt(e) {
  qt || (qt = !0, queueMicrotask(Cn)), ur.push(e);
}
function Wo() {
  qt && Cn();
}
function Er(e) {
  throw new Error("https://svelte.dev/e/lifecycle_outside_component");
}
const Dn = 0, Xo = 1;
let Pt = !1, Mt = Dn, ct = !1, ut = null, Je = !1, kr = !1;
function Pr(e) {
  Je = e;
}
function Mr(e) {
  kr = e;
}
let He = [], Ze = 0;
let I = null;
function oe(e) {
  I = e;
}
let C = null;
function X(e) {
  C = e;
}
let he = null;
function Go(e) {
  he = e;
}
let U = null, Q = 0, Le = null;
function Jo(e) {
  Le = e;
}
let In = 0, We = !1, A = null;
function Lr(e) {
  A = e;
}
function Pn() {
  return ++In;
}
function Zo() {
  return !dn;
}
function yt(e) {
  var s, u;
  var t = e.f;
  if (t & Ae)
    return !0;
  if (t & gt) {
    var r = e.deps, n = (t & Ue) !== 0;
    if (r !== null) {
      var o;
      if (t & Nt) {
        for (o = 0; o < r.length; o++)
          ((s = r[o]).reactions ?? (s.reactions = [])).push(e);
        e.f ^= Nt;
      }
      for (o = 0; o < r.length; o++) {
        var i = r[o];
        if (yt(
          /** @type {Derived} */
          i
        ) && yn(
          /** @type {Derived} */
          i
        ), n && C !== null && !We && !((u = i == null ? void 0 : i.reactions) != null && u.includes(e)) && (i.reactions ?? (i.reactions = [])).push(e), i.version > e.version)
          return !0;
      }
    }
    n || ge(e, W);
  }
  return !1;
}
function Qo(e, t) {
  for (var r = t; r !== null; ) {
    if (r.f & cr)
      try {
        r.fn(e);
        return;
      } catch {
        r.f ^= cr;
      }
    r = r.parent;
  }
  throw Pt = !1, e;
}
function $o(e) {
  return (e.f & Qe) === 0 && (e.parent === null || (e.parent.f & cr) === 0);
}
function Vt(e, t, r, n) {
  if (Pt) {
    if (r === null && (Pt = !1), $o(t))
      throw e;
    return;
  }
  r !== null && (Pt = !0);
  {
    Qo(e, t);
    return;
  }
}
function Mn(e) {
  var f;
  var t = U, r = Q, n = Le, o = I, i = We, s = he, u = A, c = e.f;
  U = /** @type {null | Value[]} */
  null, Q = 0, Le = null, I = c & (ue | pt) ? null : e, We = !Je && (c & Ue) !== 0, he = null, A = e.ctx;
  try {
    var l = (
      /** @type {Function} */
      (0, e.fn)()
    ), a = e.deps;
    if (U !== null) {
      var d;
      if (dt(e, Q), a !== null && Q > 0)
        for (a.length = Q + U.length, d = 0; d < U.length; d++)
          a[Q + d] = U[d];
      else
        e.deps = a = U;
      if (!We)
        for (d = Q; d < a.length; d++)
          ((f = a[d]).reactions ?? (f.reactions = [])).push(e);
    } else a !== null && Q < a.length && (dt(e, Q), a.length = Q);
    return l;
  } finally {
    U = t, Q = r, Le = n, I = o, We = i, he = s, A = u;
  }
}
function ei(e, t) {
  let r = t.reactions;
  if (r !== null) {
    var n = r.indexOf(e);
    if (n !== -1) {
      var o = r.length - 1;
      o === 0 ? r = t.reactions = null : (r[n] = r[o], r.pop());
    }
  }
  r === null && t.f & le && // Destroying a child effect while updating a parent effect can cause a dependency to appear
  // to be unused, when in fact it is used by the currently-updating parent. Checking `new_deps`
  // allows us to skip the expensive work of disconnecting and immediately reconnecting it
  (U === null || !U.includes(t)) && (ge(t, gt), t.f & (Ue | Nt) || (t.f ^= Nt), dt(
    /** @type {Derived} **/
    t,
    0
  ));
}
function dt(e, t) {
  var r = e.deps;
  if (r !== null)
    for (var n = t; n < r.length; n++)
      ei(e, r[n]);
}
function Ut(e) {
  var t = e.f;
  if (!(t & Qe)) {
    ge(e, W);
    var r = C, n = A;
    C = e;
    try {
      t & Ft ? Yo(e) : kn(e), En(e), xn(e);
      var o = Mn(e);
      e.teardown = typeof o == "function" ? o : null, e.version = In;
    } catch (i) {
      Vt(i, e, r, n || e.ctx);
    } finally {
      C = r;
    }
  }
}
function Ln() {
  if (Ze > 1e3) {
    Ze = 0;
    try {
      wo();
    } catch (e) {
      if (ut !== null)
        Vt(e, ut, null);
      else
        throw e;
    }
  }
  Ze++;
}
function An(e) {
  var t = e.length;
  if (t !== 0) {
    Ln();
    var r = Je;
    Je = !0;
    try {
      for (var n = 0; n < t; n++) {
        var o = e[n];
        o.f & W || (o.f ^= W);
        var i = [];
        Nn(o, i), ti(i);
      }
    } finally {
      Je = r;
    }
  }
}
function ti(e) {
  var t = e.length;
  if (t !== 0)
    for (var r = 0; r < t; r++) {
      var n = e[r];
      if (!(n.f & (Qe | ve)))
        try {
          yt(n) && (Ut(n), n.deps === null && n.first === null && n.nodes_start === null && (n.teardown === null ? Rn(n) : n.fn = null));
        } catch (o) {
          Vt(o, n, null, n.ctx);
        }
    }
}
function ri() {
  if (ct = !1, Ze > 1001)
    return;
  const e = He;
  He = [], An(e), ct || (Ze = 0, ut = null);
}
function jt(e) {
  Mt === Dn && (ct || (ct = !0, queueMicrotask(ri))), ut = e;
  for (var t = e; t.parent !== null; ) {
    t = t.parent;
    var r = t.f;
    if (r & (pt | ue)) {
      if (!(r & W)) return;
      t.f ^= W;
    }
  }
  He.push(t);
}
function Nn(e, t) {
  var r = e.first, n = [];
  e: for (; r !== null; ) {
    var o = r.f, i = (o & ue) !== 0, s = i && (o & W) !== 0, u = r.next;
    if (!s && !(o & ve))
      if (o & ht) {
        if (i)
          r.f ^= W;
        else
          try {
            yt(r) && Ut(r);
          } catch (d) {
            Vt(d, r, null, r.ctx);
          }
        var c = r.first;
        if (c !== null) {
          r = c;
          continue;
        }
      } else o & on && n.push(r);
    if (u === null) {
      let d = r.parent;
      for (; d !== null; ) {
        if (e === d)
          break e;
        var l = d.next;
        if (l !== null) {
          r = l;
          continue e;
        }
        d = d.parent;
      }
    }
    r = u;
  }
  for (var a = 0; a < n.length; a++)
    c = n[a], t.push(c), Nn(c, t);
}
function qn(e) {
  var t = Mt, r = He;
  try {
    Ln();
    const o = [];
    Mt = Xo, He = o, ct = !1, An(r);
    var n = e == null ? void 0 : e();
    return Wo(), (He.length > 0 || o.length > 0) && qn(), Ze = 0, ut = null, n;
  } finally {
    Mt = t, He = r;
  }
}
function w(e) {
  var a;
  var t = e.f, r = (t & le) !== 0;
  if (r && t & Qe) {
    var n = wn(
      /** @type {Derived} */
      e
    );
    return yr(
      /** @type {Derived} */
      e
    ), n;
  }
  if (I !== null) {
    he !== null && he.includes(e) && Eo();
    var o = I.deps;
    U === null && o !== null && o[Q] === e ? Q++ : U === null ? U = [e] : U.push(e), Le !== null && C !== null && C.f & W && !(C.f & ue) && Le.includes(e) && (ge(C, Ae), jt(C));
  } else if (r && /** @type {Derived} */
  e.deps === null)
    for (var i = (
      /** @type {Derived} */
      e
    ), s = i.parent, u = i; s !== null; )
      if (s.f & le) {
        var c = (
          /** @type {Derived} */
          s
        );
        u = c, s = c.parent;
      } else {
        var l = (
          /** @type {Effect} */
          s
        );
        (a = l.deriveds) != null && a.includes(u) || (l.deriveds ?? (l.deriveds = [])).push(u);
        break;
      }
  return r && (i = /** @type {Derived} */
  e, yt(i) && yn(i)), e.v;
}
function Ne(e) {
  const t = I;
  try {
    return I = null, e();
  } finally {
    I = t;
  }
}
const ni = ~(Ae | gt | W);
function ge(e, t) {
  e.f = e.f & ni | t;
}
function Ar(e) {
  return (
    /** @type {T} */
    On().get(e)
  );
}
function Nr(e, t) {
  return On().set(e, t), t;
}
function On(e) {
  return A === null && Er(), A.c ?? (A.c = new Map(oi(A) || void 0));
}
function oi(e) {
  let t = e.p;
  for (; t !== null; ) {
    const r = t.c;
    if (r !== null)
      return r;
    t = t.p;
  }
  return null;
}
function te(e, t = !1, r) {
  A = {
    p: A,
    c: null,
    e: null,
    m: !1,
    s: e,
    x: null,
    l: null
  };
}
function re(e) {
  const t = A;
  if (t !== null) {
    const s = t.e;
    if (s !== null) {
      var r = C, n = I;
      t.e = null;
      try {
        for (var o = 0; o < s.length; o++) {
          var i = s[o];
          X(i.effect), oe(i.reaction), et(i.fn);
        }
      } finally {
        X(r), oe(n);
      }
    }
    A = t.p, t.m = !0;
  }
  return (
    /** @type {T} */
    {}
  );
}
function ii(e) {
  if (!(typeof e != "object" || !e || e instanceof EventTarget)) {
    if (Re in e)
      dr(e);
    else if (!Array.isArray(e))
      for (let t in e) {
        const r = e[t];
        typeof r == "object" && r && Re in r && dr(r);
      }
  }
}
function dr(e, t = /* @__PURE__ */ new Set()) {
  if (typeof e == "object" && e !== null && // We don't want to traverse DOM elements
  !(e instanceof EventTarget) && !t.has(e)) {
    t.add(e), e instanceof Date && e.getTime();
    for (let n in e)
      try {
        dr(e[n], t);
      } catch {
      }
    const r = Ht(e);
    if (r !== Object.prototype && r !== Array.prototype && r !== Map.prototype && r !== Set.prototype && r !== Date.prototype) {
      const n = rn(r);
      for (let o in n) {
        const i = n[o].get;
        if (i)
          try {
            i.call(e);
          } catch {
          }
      }
    }
  }
}
function si(e) {
  var t = I, r = C;
  oe(null), X(null);
  try {
    return e();
  } finally {
    oe(t), X(r);
  }
}
const zn = /* @__PURE__ */ new Set(), fr = /* @__PURE__ */ new Set();
function ai(e, t, r, n) {
  function o(i) {
    if (n.capture || st.call(t, i), !i.cancelBubble)
      return si(() => r.call(this, i));
  }
  return e.startsWith("pointer") || e.startsWith("touch") || e === "wheel" ? wt(() => {
    t.addEventListener(e, o, n);
  }) : t.addEventListener(e, o, n), o;
}
function kt(e, t, r, n, o) {
  var i = { capture: n, passive: o }, s = ai(e, t, r, i);
  (t === document.body || t === window || t === document) && jo(() => {
    t.removeEventListener(e, s, i);
  });
}
function tt(e) {
  for (var t = 0; t < e.length; t++)
    zn.add(e[t]);
  for (var r of fr)
    r(e);
}
function st(e) {
  var _;
  var t = this, r = (
    /** @type {Node} */
    t.ownerDocument
  ), n = e.type, o = ((_ = e.composedPath) == null ? void 0 : _.call(e)) || [], i = (
    /** @type {null | Element} */
    o[0] || e.target
  ), s = 0, u = e.__root;
  if (u) {
    var c = o.indexOf(u);
    if (c !== -1 && (t === document || t === /** @type {any} */
    window)) {
      e.__root = t;
      return;
    }
    var l = o.indexOf(t);
    if (l === -1)
      return;
    c <= l && (s = c);
  }
  if (i = /** @type {Element} */
  o[s] || e.target, i !== t) {
    ao(e, "currentTarget", {
      configurable: !0,
      get() {
        return i || r;
      }
    });
    var a = I, d = C;
    oe(null), X(null);
    try {
      for (var f, v = []; i !== null; ) {
        var p = i.assignedSlot || i.parentNode || /** @type {any} */
        i.host || null;
        try {
          var m = i["__" + n];
          if (m !== void 0 && !/** @type {any} */
          i.disabled)
            if (zt(m)) {
              var [g, ...h] = m;
              g.apply(i, [e, ...h]);
            } else
              m.call(i, e);
        } catch (y) {
          f ? v.push(y) : f = y;
        }
        if (e.cancelBubble || p === t || p === null)
          break;
        i = p;
      }
      if (f) {
        for (let y of v)
          queueMicrotask(() => {
            throw y;
          });
        throw f;
      }
    } finally {
      e.__root = t, delete e.currentTarget, oe(a), X(d);
    }
  }
}
function Hn(e) {
  var t = document.createElement("template");
  return t.innerHTML = e, t.content;
}
function ft(e, t) {
  var r = (
    /** @type {Effect} */
    C
  );
  r.nodes_start === null && (r.nodes_start = e, r.nodes_end = t);
}
// @__NO_SIDE_EFFECTS__
function q(e, t) {
  var r = (t & hn) !== 0, n = (t & qo) !== 0, o, i = !e.startsWith("<!>");
  return () => {
    o === void 0 && (o = Hn(i ? e : "<!>" + e), r || (o = /** @type {Node} */
    /* @__PURE__ */ xe(o)));
    var s = (
      /** @type {TemplateNode} */
      n ? document.importNode(o, !0) : o.cloneNode(!0)
    );
    if (r) {
      var u = (
        /** @type {TemplateNode} */
        /* @__PURE__ */ xe(s)
      ), c = (
        /** @type {TemplateNode} */
        s.lastChild
      );
      ft(u, c);
    } else
      ft(s, s);
    return s;
  };
}
// @__NO_SIDE_EFFECTS__
function Ce(e, t, r = "svg") {
  var n = !e.startsWith("<!>"), o = (t & hn) !== 0, i = `<${r}>${n ? e : "<!>" + e}</${r}>`, s;
  return () => {
    if (!s) {
      var u = (
        /** @type {DocumentFragment} */
        Hn(i)
      ), c = (
        /** @type {Element} */
        /* @__PURE__ */ xe(u)
      );
      if (o)
        for (s = document.createDocumentFragment(); /* @__PURE__ */ xe(c); )
          s.appendChild(
            /** @type {Node} */
            /* @__PURE__ */ xe(c)
          );
      else
        s = /** @type {Element} */
        /* @__PURE__ */ xe(c);
    }
    var l = (
      /** @type {TemplateNode} */
      s.cloneNode(!0)
    );
    if (o) {
      var a = (
        /** @type {TemplateNode} */
        /* @__PURE__ */ xe(l)
      ), d = (
        /** @type {TemplateNode} */
        l.lastChild
      );
      ft(a, d);
    } else
      ft(l, l);
    return l;
  };
}
function Xe() {
  var e = document.createDocumentFragment(), t = document.createComment(""), r = mn();
  return e.append(t, r), ft(t, r), e;
}
function S(e, t) {
  e !== null && e.before(
    /** @type {Node} */
    t
  );
}
const li = ["touchstart", "touchmove"];
function ci(e) {
  return li.includes(e);
}
let vr = !0;
function Lt(e, t) {
  var r = t == null ? "" : typeof t == "object" ? t + "" : t;
  r !== (e.__t ?? (e.__t = e.nodeValue)) && (e.__t = r, e.nodeValue = r == null ? "" : r + "");
}
function ui(e, t) {
  return di(e, t);
}
const je = /* @__PURE__ */ new Map();
function di(e, { target: t, anchor: r, props: n = {}, events: o, context: i, intro: s = !0 }) {
  Ho();
  var u = /* @__PURE__ */ new Set(), c = (d) => {
    for (var f = 0; f < d.length; f++) {
      var v = d[f];
      if (!u.has(v)) {
        u.add(v);
        var p = ci(v);
        t.addEventListener(v, st, { passive: p });
        var m = je.get(v);
        m === void 0 ? (document.addEventListener(v, st, { passive: p }), je.set(v, 1)) : je.set(v, m + 1);
      }
    }
  };
  c(wr(zn)), fr.add(c);
  var l = void 0, a = Ko(() => {
    var d = r ?? t.appendChild(mn());
    return Te(() => {
      if (i) {
        te({});
        var f = (
          /** @type {ComponentContext} */
          A
        );
        f.c = i;
      }
      o && (n.$$events = o), vr = s, l = e(d, n) || {}, vr = !0, i && re();
    }), () => {
      var p;
      for (var f of u) {
        t.removeEventListener(f, st);
        var v = (
          /** @type {number} */
          je.get(f)
        );
        --v === 0 ? (document.removeEventListener(f, st), je.delete(f)) : je.set(f, v);
      }
      fr.delete(c), d !== r && ((p = d.parentNode) == null || p.removeChild(d));
    };
  });
  return hr.set(l, a), l;
}
let hr = /* @__PURE__ */ new WeakMap();
function fi(e, t) {
  const r = hr.get(e);
  return r ? (hr.delete(e), r(t)) : Promise.resolve();
}
const vi = [];
function hi(e, t = !1) {
  return At(e, /* @__PURE__ */ new Map(), "", vi);
}
function At(e, t, r, n, o = null) {
  if (typeof e == "object" && e !== null) {
    var i = t.get(e);
    if (i !== void 0) return i;
    if (e instanceof Map) return (
      /** @type {Snapshot<T>} */
      new Map(e)
    );
    if (e instanceof Set) return (
      /** @type {Snapshot<T>} */
      new Set(e)
    );
    if (zt(e)) {
      var s = (
        /** @type {Snapshot<any>} */
        Array(e.length)
      );
      t.set(e, s), o !== null && t.set(o, s);
      for (var u = 0; u < e.length; u += 1) {
        var c = e[u];
        u in e && (s[u] = At(c, t, r, n));
      }
      return s;
    }
    if (Ht(e) === nn) {
      s = {}, t.set(e, s), o !== null && t.set(o, s);
      for (var l in e)
        s[l] = At(e[l], t, r, n);
      return s;
    }
    if (e instanceof Date)
      return (
        /** @type {Snapshot<T>} */
        structuredClone(e)
      );
    if (typeof /** @type {T & { toJSON?: any } } */
    e.toJSON == "function")
      return At(
        /** @type {T & { toJSON(): any } } */
        e.toJSON(),
        t,
        r,
        n,
        // Associate the instance with the toJSON clone
        e
      );
  }
  if (e instanceof EventTarget)
    return (
      /** @type {Snapshot<T>} */
      e
    );
  try {
    return (
      /** @type {Snapshot<T>} */
      structuredClone(e)
    );
  } catch {
    return (
      /** @type {Snapshot<T>} */
      e
    );
  }
}
const rr = 0, Rt = 1, nr = 2;
function pi(e, t, r, n, o) {
  var i = e, s = A, u = V, c, l, a, d = Y(
    /** @type {V} */
    void 0
  ), f = Y(void 0), v = !1;
  function p(g, h) {
    v = !0, h && (X(m), oe(m), Lr(s));
    try {
      g === rr && r && (c ? Ve(c) : c = Te(() => r(i))), g === Rt && n && (l ? Ve(l) : l = Te(() => n(i, d))), g === nr && o && (a ? Ve(a) : a = Te(() => o(i, f))), g !== rr && c && Be(c, () => c = null), g !== Rt && l && Be(l, () => l = null), g !== nr && a && Be(a, () => a = null);
    } finally {
      h && (Lr(null), oe(null), X(null), qn());
    }
  }
  var m = _t(() => {
    if (u !== (u = t())) {
      if (uo(u)) {
        var g = u;
        v = !1, g.then(
          (h) => {
            g === u && (at(d, h), p(Rt, !0));
          },
          (h) => {
            if (g === u && (at(f, h), p(nr, !0), !o))
              throw f.v;
          }
        ), wt(() => {
          v || p(rr, !0);
        });
      } else
        at(d, u), p(Rt, !1);
      return () => u = V;
    }
  });
}
function ae(e, t, r = !1) {
  var n = e, o = null, i = null, s = V, u = r ? mt : 0, c = !1;
  const l = (d, f = !0) => {
    c = !0, a(f, d);
  }, a = (d, f) => {
    s !== (s = d) && (s ? (o ? Ve(o) : f && (o = Te(() => f(n))), i && Be(i, () => {
      i = null;
    })) : (i ? Ve(i) : f && (i = Te(() => f(n))), o && Be(o, () => {
      o = null;
    })));
  };
  _t(() => {
    c = !1, t(l), c || a(null, null);
  }, u);
}
function gi(e, t) {
  return t;
}
function mi(e, t, r, n) {
  for (var o = [], i = t.length, s = 0; s < i; s++)
    xr(t[s].e, o, !0);
  var u = i > 0 && o.length === 0 && r !== null;
  if (u) {
    var c = (
      /** @type {Element} */
      /** @type {Element} */
      r.parentNode
    );
    Fo(c), c.append(
      /** @type {Element} */
      r
    ), n.clear(), Pe(e, t[0].prev, t[i - 1].next);
  }
  Tn(o, () => {
    for (var l = 0; l < i; l++) {
      var a = t[l];
      u || (n.delete(a.k), Pe(e, a.prev, a.next)), Se(a.e, !u);
    }
  });
}
function _i(e, t, r, n, o, i = null) {
  var s = e, u = { flags: t, items: /* @__PURE__ */ new Map(), first: null }, c = null, l = !1;
  _t(() => {
    var a = r(), d = zt(a) ? a : a == null ? [] : wr(a), f = d.length;
    if (!(l && f === 0)) {
      l = f === 0;
      {
        var v = (
          /** @type {Effect} */
          I
        );
        wi(d, u, s, o, t, (v.f & ve) !== 0, n);
      }
      i !== null && (f === 0 ? c ? Ve(c) : c = Te(() => i(s)) : c !== null && Be(c, () => {
        c = null;
      })), r();
    }
  });
}
function wi(e, t, r, n, o, i, s) {
  var u = e.length, c = t.items, l = t.first, a = l, d, f = null, v = [], p = [], m, g, h, _;
  for (_ = 0; _ < u; _ += 1) {
    if (m = e[_], g = s(m, _), h = c.get(g), h === void 0) {
      var y = a ? (
        /** @type {TemplateNode} */
        a.e.nodes_start
      ) : r;
      f = bi(
        y,
        t,
        f,
        f === null ? t.first : f.next,
        m,
        g,
        _,
        n,
        o
      ), c.set(g, f), v = [], p = [], a = f.next;
      continue;
    }
    if (yi(h, m, _), h.e.f & ve && Ve(h.e), h !== a) {
      if (d !== void 0 && d.has(h)) {
        if (v.length < p.length) {
          var x = p[0], R;
          f = x.prev;
          var T = v[0], b = v[v.length - 1];
          for (R = 0; R < v.length; R += 1)
            qr(v[R], x, r);
          for (R = 0; R < p.length; R += 1)
            d.delete(p[R]);
          Pe(t, T.prev, b.next), Pe(t, f, T), Pe(t, b, x), a = x, f = b, _ -= 1, v = [], p = [];
        } else
          d.delete(h), qr(h, a, r), Pe(t, h.prev, h.next), Pe(t, h, f === null ? t.first : f.next), Pe(t, f, h), f = h;
        continue;
      }
      for (v = [], p = []; a !== null && a.k !== g; )
        (i || !(a.e.f & ve)) && (d ?? (d = /* @__PURE__ */ new Set())).add(a), p.push(a), a = a.next;
      if (a === null)
        continue;
      h = a;
    }
    v.push(h), f = h, a = h.next;
  }
  if (a !== null || d !== void 0) {
    for (var P = d === void 0 ? [] : wr(d); a !== null; )
      (i || !(a.e.f & ve)) && P.push(a), a = a.next;
    var F = P.length;
    if (F > 0) {
      var j = null;
      mi(t, P, j, c);
    }
  }
  C.first = t.first && t.first.e, C.last = f && f.e;
}
function yi(e, t, r, n) {
  at(e.v, t), e.i = r;
}
function bi(e, t, r, n, o, i, s, u, c) {
  var l = (c & To) !== 0, a = (c & Co) === 0, d = l ? a ? /* @__PURE__ */ fn(o) : Y(o) : o, f = c & So ? Y(s) : s, v = {
    i: f,
    v: d,
    k: i,
    a: null,
    // @ts-expect-error
    e: null,
    prev: r,
    next: n
  };
  try {
    return v.e = Te(() => u(e, d, f), Oo), v.e.prev = r && r.e, v.e.next = n && n.e, r === null ? t.first = v : (r.next = v, r.e.next = v.e), n !== null && (n.prev = v, n.e.prev = v.e), v;
  } finally {
  }
}
function qr(e, t, r) {
  for (var n = e.next ? (
    /** @type {TemplateNode} */
    e.next.e.nodes_start
  ) : r, o = t ? (
    /** @type {TemplateNode} */
    t.e.nodes_start
  ) : r, i = (
    /** @type {TemplateNode} */
    e.e.nodes_start
  ); i !== n; ) {
    var s = (
      /** @type {TemplateNode} */
      /* @__PURE__ */ Bt(i)
    );
    o.before(i), i = s;
  }
}
function Pe(e, t, r) {
  t === null ? e.first = r : (t.next = r, t.e.next = r && r.e), r !== null && (r.prev = t, r.e.prev = t && t.e);
}
function Rr(e, t, ...r) {
  var n = e, o = Ke, i;
  _t(() => {
    o !== (o = t()) && (i && (Se(i), i = null), i = Te(() => (
      /** @type {SnippetFn} */
      o(n, ...r)
    )));
  }, mt);
}
function ie(e, t) {
  wt(() => {
    var r = e.getRootNode(), n = (
      /** @type {ShadowRoot} */
      r.host ? (
        /** @type {ShadowRoot} */
        r
      ) : (
        /** @type {Document} */
        r.head ?? /** @type {Document} */
        r.ownerDocument.head
      )
    );
    if (!n.querySelector("#" + t.hash)) {
      const o = document.createElement("style");
      o.id = t.hash, o.textContent = t.code, n.appendChild(o);
    }
  });
}
function xi(e, t, r) {
  et(() => {
    var n = Ne(() => t(e, r == null ? void 0 : r()) || {});
    if (r && (n != null && n.update)) {
      var o = !1, i = (
        /** @type {any} */
        {}
      );
      bn(() => {
        var s = r();
        ii(s), o && cn(i, s) && (i = s, n.update(s));
      }), o = !0;
    }
    if (n != null && n.destroy)
      return () => (
        /** @type {Function} */
        n.destroy()
      );
  });
}
function Ot(e, t, r, n) {
  var o = e.__attributes ?? (e.__attributes = {});
  o[t] !== (o[t] = r) && (t === "style" && "__styles" in e && (e.__styles = {}), t === "loading" && (e[po] = r), r == null ? e.removeAttribute(t) : typeof r != "string" && Ei(e).includes(t) ? e[t] = r : e.setAttribute(t, r));
}
var Or = /* @__PURE__ */ new Map();
function Ei(e) {
  var t = Or.get(e.nodeName);
  if (t) return t;
  Or.set(e.nodeName, t = []);
  for (var r, n = e, o = Element.prototype; o !== n; ) {
    r = rn(n);
    for (var i in r)
      r[i].set && t.push(i);
    n = Ht(n);
  }
  return t;
}
function zr(e, t, r) {
  if (r) {
    if (e.classList.contains(t)) return;
    e.classList.add(t);
  } else {
    if (!e.classList.contains(t)) return;
    e.classList.remove(t);
  }
}
function H(e, t, r, n) {
  var o = e.__styles ?? (e.__styles = {});
  o[t] !== r && (o[t] = r, r == null ? e.style.removeProperty(t) : e.style.setProperty(t, r, ""));
}
const ki = () => performance.now(), Ee = {
  // don't access requestAnimationFrame eagerly outside method
  // this allows basic testing of user code without JSDOM
  // bunder will eval and remove ternary when the user's app is built
  tick: (
    /** @param {any} _ */
    (e) => requestAnimationFrame(e)
  ),
  now: () => ki(),
  tasks: /* @__PURE__ */ new Set()
};
function Fn() {
  const e = Ee.now();
  Ee.tasks.forEach((t) => {
    t.c(e) || (Ee.tasks.delete(t), t.f());
  }), Ee.tasks.size !== 0 && Ee.tick(Fn);
}
function Ri(e) {
  let t;
  return Ee.tasks.size === 0 && Ee.tick(Fn), {
    promise: new Promise((r) => {
      Ee.tasks.add(t = { c: e, f: r });
    }),
    abort() {
      Ee.tasks.delete(t);
    }
  };
}
function Tt(e, t) {
  e.dispatchEvent(new CustomEvent(t));
}
function Ti(e) {
  if (e === "float") return "cssFloat";
  if (e === "offset") return "cssOffset";
  if (e.startsWith("--")) return e;
  const t = e.split("-");
  return t.length === 1 ? t[0] : t[0] + t.slice(1).map(
    /** @param {any} word */
    (r) => r[0].toUpperCase() + r.slice(1)
  ).join("");
}
function Hr(e) {
  const t = {}, r = e.split(";");
  for (const n of r) {
    const [o, i] = n.split(":");
    if (!o || i === void 0) break;
    const s = Ti(o.trim());
    t[s] = i.trim();
  }
  return t;
}
const Si = (e) => e;
function Fr(e, t, r, n) {
  var o = (e & Lo) !== 0, i = (e & Ao) !== 0, s = o && i, u = (e & No) !== 0, c = s ? "both" : o ? "in" : "out", l, a = t.inert, d, f;
  function v() {
    var _ = I, y = C;
    oe(null), X(null);
    try {
      return l ?? (l = r()(t, (n == null ? void 0 : n()) ?? /** @type {P} */
      {}, {
        direction: c
      }));
    } finally {
      oe(_), X(y);
    }
  }
  var p = {
    is_global: u,
    in() {
      var _;
      if (t.inert = a, !o) {
        f == null || f.abort(), (_ = f == null ? void 0 : f.reset) == null || _.call(f);
        return;
      }
      i || d == null || d.abort(), Tt(t, "introstart"), d = pr(t, v(), f, 1, () => {
        Tt(t, "introend"), d == null || d.abort(), d = l = void 0;
      });
    },
    out(_) {
      if (!i) {
        _ == null || _(), l = void 0;
        return;
      }
      t.inert = !0, Tt(t, "outrostart"), f = pr(t, v(), d, 0, () => {
        Tt(t, "outroend"), _ == null || _();
      });
    },
    stop: () => {
      d == null || d.abort(), f == null || f.abort();
    }
  }, m = (
    /** @type {Effect} */
    C
  );
  if ((m.transitions ?? (m.transitions = [])).push(p), o && vr) {
    var g = u;
    if (!g) {
      for (var h = (
        /** @type {Effect | null} */
        m.parent
      ); h && h.f & mt; )
        for (; (h = h.parent) && !(h.f & Ft); )
          ;
      g = !h || (h.f & sn) !== 0;
    }
    g && et(() => {
      Ne(() => p.in());
    });
  }
}
function pr(e, t, r, n, o) {
  var i = n === 1;
  if (co(t)) {
    var s, u = !1;
    return wt(() => {
      if (!u) {
        var g = t({ direction: i ? "in" : "out" });
        s = pr(e, g, r, n, o);
      }
    }), {
      abort: () => {
        u = !0, s == null || s.abort();
      },
      deactivate: () => s.deactivate(),
      reset: () => s.reset(),
      t: () => s.t()
    };
  }
  if (r == null || r.deactivate(), !(t != null && t.duration))
    return o(), {
      abort: Ke,
      deactivate: Ke,
      reset: Ke,
      t: () => n
    };
  const { delay: c = 0, css: l, tick: a, easing: d = Si } = t;
  var f = [];
  if (i && r === void 0 && (a && a(0, 1), l)) {
    var v = Hr(l(0, 1));
    f.push(v, v);
  }
  var p = () => 1 - n, m = e.animate(f, { duration: c });
  return m.onfinish = () => {
    var g = (r == null ? void 0 : r.t()) ?? 1 - n;
    r == null || r.abort();
    var h = n - g, _ = (
      /** @type {number} */
      t.duration * Math.abs(h)
    ), y = [];
    if (_ > 0) {
      if (l)
        for (var x = Math.ceil(_ / 16.666666666666668), R = 0; R <= x; R += 1) {
          var T = g + h * d(R / x), b = l(T, 1 - T);
          y.push(Hr(b));
        }
      p = () => {
        var P = (
          /** @type {number} */
          /** @type {globalThis.Animation} */
          m.currentTime
        );
        return g + h * d(P / _);
      }, a && Ri(() => {
        if (m.playState !== "running") return !1;
        var P = p();
        return a(P, 1 - P), !0;
      });
    }
    m = e.animate(y, { duration: _, fill: "forwards" }), m.onfinish = () => {
      p = () => n, a == null || a(n, 1 - n), o();
    };
  }, {
    abort: () => {
      m && (m.cancel(), m.effect = null, m.onfinish = Ke);
    },
    deactivate: () => {
      o = Ke;
    },
    reset: () => {
      n === 0 && (a == null || a(1, 0));
    },
    t: () => p()
  };
}
function gr(e, t, r) {
  if (e.multiple)
    return Di(e, t);
  for (var n of e.options) {
    var o = Bn(n);
    if (zo(o, t)) {
      n.selected = !0;
      return;
    }
  }
  (!r || t !== void 0) && (e.selectedIndex = -1);
}
function Ci(e, t) {
  let r = !0;
  et(() => {
    t && gr(e, Ne(t), r), r = !1;
    var n = new MutationObserver(() => {
      var o = e.__value;
      gr(e, o);
    });
    return n.observe(e, {
      // Listen to option element changes
      childList: !0,
      subtree: !0,
      // because of <optgroup>
      // Listen to option element value attribute changes
      // (doesn't get notified of select value changes,
      // because that property is not reflected as an attribute)
      attributes: !0,
      attributeFilter: ["value"]
    }), () => {
      n.disconnect();
    };
  });
}
function Di(e, t) {
  for (var r of e.options)
    r.selected = ~t.indexOf(Bn(r));
}
function Bn(e) {
  return "__value" in e ? e.__value : e.value;
}
function Br(e, t) {
  return e === t || (e == null ? void 0 : e[Re]) === t;
}
function Vn(e = {}, t, r, n) {
  return et(() => {
    var o, i;
    return bn(() => {
      o = i, i = [], Ne(() => {
        e !== r(...i) && (t(e, ...i), o && Br(r(...o), e) && t(null, ...o));
      });
    }), () => {
      wt(() => {
        i && Br(r(...i), e) && t(null, ...i);
      });
    };
  }), e;
}
let St = !1;
function Ii(e) {
  var t = St;
  try {
    return St = !1, [e(), St];
  } finally {
    St = t;
  }
}
function Pi(e) {
  for (var t = C, r = C; t !== null && !(t.f & (ue | pt)); )
    t = t.parent;
  try {
    return X(t), e();
  } finally {
    X(r);
  }
}
function Kt(e, t, r, n) {
  var T;
  var o = (r & Do) !== 0, i = !dn, s = (r & Po) !== 0, u = (r & Mo) !== 0, c = !1, l;
  s ? [l, c] = Ii(() => (
    /** @type {V} */
    e[t]
  )) : l = /** @type {V} */
  e[t];
  var a = Re in e || ho in e, d = ((T = Ge(e, t)) == null ? void 0 : T.set) ?? (a && s && t in e ? (b) => e[t] = b : void 0), f = (
    /** @type {V} */
    n
  ), v = !0, p = !1, m = () => (p = !0, v && (v = !1, u ? f = Ne(
    /** @type {() => V} */
    n
  ) : f = /** @type {V} */
  n), f);
  l === void 0 && n !== void 0 && (d && i && yo(), l = m(), d && d(l));
  var g;
  if (g = () => {
    var b = (
      /** @type {V} */
      e[t]
    );
    return b === void 0 ? m() : (v = !0, p = !1, b);
  }, !(r & Io))
    return g;
  if (d) {
    var h = e.$$legacy;
    return function(b, P) {
      return arguments.length > 0 ? ((!P || h || c) && d(P ? g() : b), b) : g();
    };
  }
  var _ = !1, y = !1, x = /* @__PURE__ */ fn(l), R = Pi(
    () => /* @__PURE__ */ ke(() => {
      var b = g(), P = w(x);
      return _ ? (_ = !1, y = !0, P) : (y = !1, x.v = b);
    })
  );
  return o || (R.equals = un), function(b, P) {
    if (arguments.length > 0) {
      const F = P ? w(R) : s ? z(b) : b;
      return R.equals(F) || (_ = !0, L(x, F), p && f !== void 0 && (f = F), Ne(() => w(R))), b;
    }
    return w(R);
  };
}
function Yt(e) {
  A === null && Er(), br(() => {
    const t = Ne(e);
    if (typeof t == "function") return (
      /** @type {() => void} */
      t
    );
  });
}
function Mi(e) {
  A === null && Er(), Yt(() => () => Ne(e));
}
class Un {
  constructor() {
    D(this, "listeners", {});
  }
  on(t, r) {
    this.listeners[t] || (this.listeners[t] = []), this.listeners[t].push(r);
  }
  off(t, r) {
    this.listeners[t] && (this.listeners[t] = this.listeners[t].filter((n) => n !== r));
  }
  emit(t, r) {
    this.listeners[t] && this.listeners[t].forEach((n) => n(r));
  }
}
const Li = (e) => {
  if (typeof e == "string")
    return new RegExp(`^${e}$`).source;
  if (e)
    return e.source;
}, Ai = "5";
typeof window < "u" && (window.__svelte || (window.__svelte = { v: /* @__PURE__ */ new Set() })).v.add(Ai);
const Wt = 2147483647, Ct = "16px", Ni = (e) => {
  let t = "";
  const [r, n] = e.split("-");
  return r === "top" ? t += `top: ${Ct};` : r === "bottom" && (t += `bottom: ${Ct};`), n === "left" ? t += `left: ${Ct};` : n === "right" && (t += `right: ${Ct};`), t;
};
function jn(e) {
  return --e * e * ((1.70158 + 1) * e + 1.70158) + 1;
}
const Kn = (e, t, r) => e + (t - e) * r;
function qi(e, { isInitialRender: t }) {
  return {
    easing: jn,
    duration: 250,
    delay: t() ? 0 : 200,
    css: (r) => `transform: scale(${Kn(0.8, 1, r)}); opacity: ${r};`
  };
}
function Oi(e) {
  return {
    easing: jn,
    duration: 200,
    css: (t) => `transform: scale(${Kn(0.9, 1, t)}); opacity: ${t};`
  };
}
var zi = (e, t, r) => {
  var n;
  t(), (n = r.onClose) == null || n.call(r);
}, Hi = /* @__PURE__ */ q('<div class="root svelte-1j4v21k" role="dialog" aria-labelledBy="capture-dialog-title"><header class="svelte-1j4v21k"><svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4.82492" y="10.3379" width="4.79525" height="4.77151" fill="currentColor"></rect><path d="M0.0534058 5.54297H4.82492V10.3382H0.0534058V5.54297Z" fill="currentColor"></path><path d="M4.82492 0.771484H9.62017V5.543H4.82492V0.771484Z" fill="currentColor"></path></svg> <button class="close svelte-1j4v21k" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M18 6 6 18"></path><path d="m6 6 12 12"></path></svg></button></header> <div class="content svelte-1j4v21k"><!></div></div>');
const Fi = {
  hash: "svelte-1j4v21k",
  code: ".root.svelte-1j4v21k {overflow:hidden;position:fixed;background:var(--background-secondary);border:solid 1px var(--color-border);border-radius:8px;box-shadow:rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;}header.svelte-1j4v21k {color:#35de4f;font-weight:bold;height:54px;display:flex;align-items:center;justify-content:center;position:relative;z-index:2;background:var(--background-secondary);}.close.svelte-1j4v21k {position:absolute;right:8px;top:8px;width:32px;height:32px;display:inline-flex;align-items:center;justify-content:center;border-radius:8px;transition:background 0.1s ease-out;color:var(--text-color-secondary);svg {width:18px;height:18px;transition:color 0.15s ease-out;}&:hover {background:rgba(0, 0, 0, 0.05);}}.content.svelte-1j4v21k {position:relative;z-index:1;}"
};
function Yn(e, t) {
  te(t, !0), ie(e, Fi);
  const r = Ar("close"), n = Ar("isInitialRender");
  var o = Hi();
  const i = /* @__PURE__ */ ke(() => Ni(t.position));
  var s = k(o), u = O(k(s), 2);
  u.__click = [zi, r, t];
  var c = O(s, 2), l = k(c);
  Rr(l, () => t.children), ce(() => {
    Ot(o, "style", w(i)), H(o, "z-index", Wt);
  }), Fr(5, o, () => qi, () => ({ isInitialRender: n })), Fr(6, o, () => Oi), S(e, o), re();
}
tt(["click"]);
var Bi = /* @__PURE__ */ Ce('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><!></svg>');
function Me(e, t) {
  te(t, !0);
  const r = Kt(t, "size", 3, 18);
  var n = Bi(), o = k(n);
  Rr(o, () => t.children), ce(() => {
    Ot(n, "width", r()), Ot(n, "height", r());
  }), S(e, n), re();
}
var Vi = (e, t) => {
  L(t, "screenshot");
}, Ui = (e, t) => {
  L(t, "recording");
}, ji = (e, t) => t.takeScreenshot(), Ki = /* @__PURE__ */ Ce('<path d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z"></path><circle cx="12" cy="13" r="3"></circle>', 1), Yi = (e, t) => {
  t.setView({
    name: "capture-area",
    initialPointer: [e.clientX, e.clientY]
  });
}, Wi = /* @__PURE__ */ Ce('<path d="M12.034 12.681a.498.498 0 0 1 .647-.647l9 3.5a.5.5 0 0 1-.033.943l-3.444 1.068a1 1 0 0 0-.66.66l-1.067 3.443a.5.5 0 0 1-.943.033z"></path><path d="M5 3a2 2 0 0 0-2 2"></path><path d="M19 3a2 2 0 0 1 2 2"></path><path d="M5 21a2 2 0 0 1-2-2"></path><path d="M9 3h1"></path><path d="M9 21h2"></path><path d="M14 3h1"></path><path d="M3 9v1"></path><path d="M21 9v2"></path><path d="M3 14v1"></path>', 1), Xi = /* @__PURE__ */ q('<button class="option-button svelte-478poo"><div class="icon svelte-478poo"><!></div> <div class="option-name svelte-478poo">Capture area</div> <div class="desc svelte-478poo">Drag to capture</div></button>'), Gi = /* @__PURE__ */ q('<div class="content svelte-478poo"><button class="option-button svelte-478poo"><div class="icon svelte-478poo"><!></div> <div class="option-name svelte-478poo">Capture page</div> <div class="desc svelte-478poo">Click to capture</div></button> <!></div>'), Ji = (e, t) => t.startRecording(), Zi = /* @__PURE__ */ Ce('<path d="m16 13 5.223 3.482a.5.5 0 0 0 .777-.416V7.87a.5.5 0 0 0-.752-.432L16 10.5"></path><rect x="2" y="6" width="14" height="12" rx="2"></rect>', 1), Qi = (e, t) => {
  t.setView({ name: "screen-recording-options" });
}, $i = /* @__PURE__ */ Ce('<circle cx="12" cy="10" r="8"></circle><circle cx="12" cy="10" r="3"></circle><path d="M7 22h10"></path><path d="M12 22v-4"></path>', 1), es = /* @__PURE__ */ q('<div class="content svelte-478poo"><button class="option-button svelte-478poo"><div class="icon svelte-478poo"><!></div> <div class="option-name svelte-478poo">Record screen</div> <div class="desc svelte-478poo">Screen only</div></button> <button class="option-button svelte-478poo"><div class="icon svelte-478poo"><!></div> <div class="option-name svelte-478poo">Record video</div> <div class="desc svelte-478poo">Screen and camera</div></button></div>'), ts = /* @__PURE__ */ q('<div class="root svelte-478poo"><div class="toggle-wrapper svelte-478poo"><div class="toggle svelte-478poo"><div class="indicator svelte-478poo"></div> <button class="toggle-button svelte-478poo">Screenshot</button> <button class="toggle-button svelte-478poo">Recording</button></div></div> <!></div>');
const rs = {
  hash: "svelte-478poo",
  code: ".root.svelte-478poo {display:grid;width:360px;}.toggle-wrapper.svelte-478poo {text-align:center;padding-bottom:16px;background:var(--background-secondary);}.toggle.svelte-478poo {width:268px;height:46px;position:relative;display:inline-grid;grid-template-columns:1fr 1fr;background:var(--background-tertiary);border-radius:100px;overflow:hidden;padding:4px;gap:4px;box-shadow:inset 0px 0px 6px rgba(0, 0, 0, 0.04);}.toggle-button.svelte-478poo {height:100%;position:relative;z-index:2;border-radius:100px;font-weight:bold;color:#13181ebd;transition:all 0.15s;font-size:14px;&:hover {background:rgba(0, 0, 0, 0.05);}}.selected.svelte-478poo {color:var(--button-primary-text-color);&:hover {background:transparent;}}.indicator.svelte-478poo {transition:left 0.1s ease-out;position:absolute;height:calc(100% - 8px);width:calc(50% - 4px);background:var(--button-primary-bg);top:4px;left:4px;border-radius:100px;z-index:1;}.content.svelte-478poo {box-shadow:0 0 5px rgba(0, 0, 0, 0.05);background:var(--background-primary);border-radius:8px 8px 0 0;padding:16px;display:flex;gap:8px;}.option-button.svelte-478poo {flex:1 1 auto;background:#fff;box-shadow:inset 0px 2px 3px #fff;border:solid 1px #ebebeb;display:flex;flex-direction:column;align-items:center;padding:16px;border-radius:6px;transition:border 0.15s;&:hover {background-image:linear-gradient(to top, #fff, #fafcfd);}}.option-name.svelte-478poo {font-weight:bold;font-size:15px;}.desc.svelte-478poo {margin-top:2px;color:var(--text-color-secondary);font-size:12px;}.icon.svelte-478poo {display:inline-flex;width:40px;height:40px;align-items:center;justify-content:center;background:rgba(0, 0, 0, 0.05);border-radius:50%;color:var(--text-color-secondary);margin-bottom:12px;}"
};
function ns(e, t) {
  te(t, !0), ie(e, rs);
  const r = Kt(t, "initialTab", 3, "screenshot");
  let n = ee(z(r()));
  Yn(e, {
    get position() {
      return t.dialogPosition;
    },
    children: (o, i) => {
      var s = ts(), u = k(s), c = k(u), l = k(c), a = O(l, 2);
      a.__click = [Vi, n];
      var d = O(a, 2);
      d.__click = [Ui, n];
      var f = O(u, 2);
      {
        var v = (m) => {
          var g = Gi(), h = k(g);
          h.__click = [ji, t];
          var _ = k(h), y = k(_);
          Me(y, {
            children: (T, b) => {
              var P = Ki();
              S(T, P);
            },
            $$slots: { default: !0 }
          });
          var x = O(h, 2);
          {
            var R = (T) => {
              var b = Xi();
              b.__click = [Yi, t];
              var P = k(b), F = k(P);
              Me(F, {
                children: (j, K) => {
                  var ne = Wi();
                  S(j, ne);
                },
                $$slots: { default: !0 }
              }), S(T, b);
            };
            ae(x, (T) => {
              t.canCaptureArea && T(R);
            });
          }
          S(m, g);
        }, p = (m) => {
          var g = Xe(), h = ze(g);
          {
            var _ = (y) => {
              var x = es(), R = k(x);
              R.__click = [Ji, t];
              var T = k(R), b = k(T);
              Me(b, {
                children: (K, ne) => {
                  var de = Zi();
                  S(K, de);
                },
                $$slots: { default: !0 }
              });
              var P = O(R, 2);
              P.__click = [Qi, t];
              var F = k(P), j = k(F);
              Me(j, {
                children: (K, ne) => {
                  var de = $i();
                  S(K, de);
                },
                $$slots: { default: !0 }
              }), S(y, x);
            };
            ae(
              h,
              (y) => {
                w(n) === "recording" && y(_);
              },
              !0
            );
          }
          S(m, g);
        };
        ae(f, (m) => {
          w(n) === "screenshot" ? m(v) : m(p, !1);
        });
      }
      ce(() => {
        H(l, "left", w(n) === "screenshot" ? "4px" : "50%"), zr(a, "selected", w(n) === "screenshot"), zr(d, "selected", w(n) === "recording");
      }), S(o, s);
    },
    $$slots: { default: !0 }
  }), re();
}
tt(["click"]);
var os = /* @__PURE__ */ q('<div class="box svelte-1m09p7k"></div>'), is = /* @__PURE__ */ q('<div class="explainer-wrapper svelte-1m09p7k"><div class="explainer svelte-1m09p7k">Click and drag <div class="subtext svelte-1m09p7k">ESC to cancel</div></div></div>'), ss = /* @__PURE__ */ q('<div class="root svelte-1m09p7k"><!></div>');
const as = {
  hash: "svelte-1m09p7k",
  code: ".root.svelte-1m09p7k {position:fixed;top:0;left:0;width:100%;height:100%;cursor:crosshair !important;}.box.svelte-1m09p7k {position:absolute;border:solid 1px #bdee63;background:#bdee6380;}.explainer-wrapper.svelte-1m09p7k {position:absolute;}.explainer.svelte-1m09p7k {background:var(--background-contrast);padding:8px 24px;border-radius:100px;font-size:14px;font-weight:bold;color:var(--text-color-contrast);position:absolute;right:-2px;bottom:-2px;white-space:nowrap;text-align:center;border:solid 1px #333333;}.subtext.svelte-1m09p7k {font-weight:normal;font-size:10px;line-height:18px;}"
};
function ls(e, t) {
  te(t, !0), ie(e, as);
  let r = ee(!1), n = ee(z([0, 0])), o = ee(z(t.initialPointer)), i = ee(!1);
  const s = /* @__PURE__ */ ke(() => Math.min(w(n)[0], w(o)[0])), u = /* @__PURE__ */ ke(() => Math.min(w(n)[1], w(o)[1])), c = /* @__PURE__ */ ke(() => Math.abs(w(o)[0] - w(n)[0])), l = /* @__PURE__ */ ke(() => Math.abs(w(o)[1] - w(n)[1])), a = (h) => {
    L(r, !0), L(n, z([h.clientX, h.clientY]));
  }, d = (h) => {
    L(o, z([h.clientX, h.clientY]));
  }, f = (h) => {
    h.key === "Escape" && t.setView({ name: "initial" });
  }, v = () => {
    L(i, !0), t.takeScreenshot({
      x: w(s),
      y: w(u),
      width: w(c),
      height: w(l)
    });
  };
  var p = ss();
  kt("pointerdown", Ye, a), kt("pointermove", Ye, d), kt("pointerup", Ye, v), kt("keydown", Ye, f), H(p, "z-index", Wt);
  var m = k(p);
  {
    var g = (h) => {
      var _ = Xe(), y = ze(_);
      {
        var x = (T) => {
          var b = os();
          ce(() => {
            H(b, "top", `${w(u) ?? ""}px`), H(b, "left", `${w(s) ?? ""}px`), H(b, "width", `${w(c) ?? ""}px`), H(b, "height", `${w(l) ?? ""}px`);
          }), S(T, b);
        }, R = (T) => {
          var b = is();
          ce(() => {
            H(b, "left", `${w(o)[0] ?? ""}px`), H(b, "top", `${w(o)[1] ?? ""}px`);
          }), S(T, b);
        };
        ae(y, (T) => {
          w(r) ? T(x) : T(R, !1);
        });
      }
      S(h, _);
    };
    ae(m, (h) => {
      w(i) || h(g);
    });
  }
  S(e, p), re();
}
var cs = /* @__PURE__ */ q('<div class="root svelte-yx3kiu"></div>');
const us = {
  hash: "svelte-yx3kiu",
  code: `.root.svelte-yx3kiu {width:200px;height:200px;overflow:hidden;iframe,
    video {border:0;width:100%;height:100%;}}`
};
function Wn(e, t) {
  te(t, !0), ie(e, us);
  let r;
  br(() => {
    t.videoDeviceId ? (r.innerHTML = "", t.screenRecorder.createVideoElement(r, t.videoDeviceId)) : r.innerHTML = "";
  });
  var n = cs();
  Vn(n, (o) => r = o, () => r), S(e, n), re();
}
var ds = /* @__PURE__ */ q("<option> </option>"), fs = /* @__PURE__ */ q('<button class="trigger svelte-7yi96u"><!> <div class="label svelte-7yi96u"><span> </span></div> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"></path></svg> <select class="hidden-select svelte-7yi96u"><option> </option><!></select></button>');
const vs = {
  hash: "svelte-7yi96u",
  code: ".trigger.svelte-7yi96u {width:100%;overflow:hidden;height:44px;background-color:var(--background-primary);border:solid 1px var(--color-border);border-radius:4px;display:flex;align-items:center;gap:12px;padding:0 12px 0;position:relative;text-align:left;svg {flex:0 0 auto;}}.label.svelte-7yi96u {flex:1 1 auto !important;min-width:0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}.hidden-select.svelte-7yi96u {opacity:0;position:absolute;width:100%;height:100%;appearance:none;left:0;top:0;cursor:pointer;}"
};
function Vr(e, t) {
  te(t, !0), ie(e, vs);
  const r = /* @__PURE__ */ ke(() => {
    var v;
    return ((v = t.devices.find((p) => p.deviceId === t.value)) == null ? void 0 : v.label) ?? t.emptyLabel;
  });
  var n = fs(), o = k(n);
  Rr(o, () => t.children);
  var i = O(o, 2), s = k(i), u = k(s), c = O(i, 4);
  Ci(c, () => t.value ?? "none");
  var l;
  c.__change = (v) => {
    const p = v.target.value;
    t.onChange(p === "none" ? void 0 : p);
  };
  var a = k(c);
  a.value = (a.__value = "none") == null ? "" : "none";
  var d = k(a), f = O(a);
  _i(f, 17, () => t.devices, gi, (v, p) => {
    var m = ds(), g = {}, h = k(m);
    ce(() => {
      g !== (g = w(p).deviceId) && (m.value = (m.__value = w(p).deviceId) == null ? "" : w(p).deviceId), Lt(h, w(p).label);
    }), S(v, m);
  }), ce(() => {
    Lt(u, w(r)), l !== (l = t.value ?? "none") && (c.value = (c.__value = t.value ?? "none") == null ? "" : t.value ?? "none", gr(c, t.value ?? "none")), Lt(d, t.emptyLabel);
  }), S(e, n), re();
}
tt(["change"]);
var hs = /* @__PURE__ */ Ce('<path d="m16 13 5.223 3.482a.5.5 0 0 0 .777-.416V7.87a.5.5 0 0 0-.752-.432L16 10.5"></path><rect x="2" y="6" width="14" height="12" rx="2"></rect>', 1), ps = /* @__PURE__ */ Ce('<path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"></path><path d="M19 10v2a7 7 0 0 1-14 0v-2"></path><line x1="12" x2="12" y1="19" y2="22"></line>', 1), gs = (e, t, r, n) => {
  t.startRecording({
    videoDeviceId: w(r),
    audioDeviceId: w(n)
  });
}, ms = /* @__PURE__ */ q('<div><div class="preview svelte-1974xwk"><div class="video svelte-1974xwk"><!></div></div> <div class="controls svelte-1974xwk"><!> <!> <button class="button svelte-1974xwk">Start recording</button></div></div>');
const _s = {
  hash: "svelte-1974xwk",
  code: ".preview.svelte-1974xwk {display:flex;justify-content:center;align-items:center;background:var(--background-secondary);padding-bottom:16px;}.video.svelte-1974xwk {display:inline-flex;overflow:hidden;border-radius:6px;}.controls.svelte-1974xwk {background:var(--background-primary);padding:16px;display:grid;gap:8px;border-radius:16px 16px 0 0;box-shadow:0 0 5px rgba(0, 0, 0, 0.05);}.button.svelte-1974xwk {margin-top:8px;background:var(--background-contrast);color:var(--text-color-contrast);font-weight:bold;text-align:center;border-radius:4px;height:54px;}"
};
function ws(e, t) {
  te(t, !0), ie(e, _s);
  const r = t.devices.filter((g) => g.kind === "audioinput"), n = t.devices.filter((g) => g.kind === "videoinput");
  let o = ee(z(n[0].deviceId)), i = ee(z(r[0].deviceId));
  function s(g) {
    L(o, z(g));
  }
  function u(g) {
    L(i, z(g));
  }
  var c = ms(), l = k(c), a = k(l), d = k(a);
  Wn(d, {
    get videoDeviceId() {
      return w(o);
    },
    get screenRecorder() {
      return t.screenRecorder;
    }
  });
  var f = O(l, 2), v = k(f);
  Vr(v, {
    devices: n,
    get value() {
      return w(o);
    },
    onChange: s,
    emptyLabel: "No camera",
    children: (g, h) => {
      Me(g, {
        children: (_, y) => {
          var x = hs();
          S(_, x);
        },
        $$slots: { default: !0 }
      });
    },
    $$slots: { default: !0 }
  });
  var p = O(v, 2);
  Vr(p, {
    devices: r,
    get value() {
      return w(i);
    },
    onChange: u,
    emptyLabel: "No microphone",
    children: (g, h) => {
      Me(g, {
        children: (_, y) => {
          var x = ps();
          S(_, x);
        },
        $$slots: { default: !0 }
      });
    },
    $$slots: { default: !0 }
  });
  var m = O(p, 2);
  m.__click = [
    gs,
    t,
    o,
    i
  ], S(e, c), re();
}
tt(["click"]);
var ys = /* @__PURE__ */ q('<span class="spinner svelte-r3eep"><span class="leaf svelte-r3eep"></span> <span class="leaf svelte-r3eep"></span> <span class="leaf svelte-r3eep"></span> <span class="leaf svelte-r3eep"></span> <span class="leaf svelte-r3eep"></span> <span class="leaf svelte-r3eep"></span> <span class="leaf svelte-r3eep"></span> <span class="leaf svelte-r3eep"></span></span>');
const bs = {
  hash: "svelte-r3eep",
  code: `
  @keyframes svelte-r3eep-leaf-fade {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.25;
    }
  }.spinner.svelte-r3eep {--spinner-animation-duration: 800ms;--spinner-opacity: 0.65;display:block;position:relative;opacity:var(--spinner-opacity);}.leaf.svelte-r3eep {position:absolute;top:0;left:calc(50% - 12.5% / 2);width:12.5%;height:100%;
    animation: svelte-r3eep-leaf-fade var(--spinner-animation-duration) linear infinite;&:before {content:"";display:block;width:100%;height:27%;border-radius:2px;background-color:currentColor;}&:where(:where(.svelte-r3eep):nth-child(1)) {transform:rotate(0deg);animation-delay:calc(-8 / 8 * var(--spinner-animation-duration));}&:where(:where(.svelte-r3eep):nth-child(2)) {transform:rotate(45deg);animation-delay:calc(-7 / 8 * var(--spinner-animation-duration));}&:where(:where(.svelte-r3eep):nth-child(3)) {transform:rotate(90deg);animation-delay:calc(-6 / 8 * var(--spinner-animation-duration));}&:where(:where(.svelte-r3eep):nth-child(4)) {transform:rotate(135deg);animation-delay:calc(-5 / 8 * var(--spinner-animation-duration));}&:where(:where(.svelte-r3eep):nth-child(5)) {transform:rotate(180deg);animation-delay:calc(-4 / 8 * var(--spinner-animation-duration));}&:where(:where(.svelte-r3eep):nth-child(6)) {transform:rotate(225deg);animation-delay:calc(-3 / 8 * var(--spinner-animation-duration));}&:where(:where(.svelte-r3eep):nth-child(7)) {transform:rotate(270deg);animation-delay:calc(-2 / 8 * var(--spinner-animation-duration));}&:where(:where(.svelte-r3eep):nth-child(8)) {transform:rotate(315deg);animation-delay:calc(-1 / 8 * var(--spinner-animation-duration));}}`
};
function xs(e, t) {
  ie(e, bs);
  const r = Kt(t, "size", 3, 18);
  var n = ys();
  ce(() => {
    H(n, "width", `${r() ?? ""}px`), H(n, "height", `${r() ?? ""}px`);
  }), S(e, n);
}
var Es = (e, t) => {
  t.setView({ name: "initial", initialTab: "recording" });
}, ks = /* @__PURE__ */ q(`<div class="copy svelte-bg8huv"><h4 class="svelte-bg8huv">Unable to load devices</h4> <p class="svelte-bg8huv">We weren't able to access your camera. Please check your browser
          settings.</p> <button class="button svelte-bg8huv">Go back</button></div>`), Rs = /* @__PURE__ */ q('<div class="copy svelte-bg8huv"><!> <h4 class="svelte-bg8huv">Loading devices</h4> <p class="svelte-bg8huv">Please accept permissions when prompted by your browser</p></div>'), Ts = /* @__PURE__ */ q('<div class="root svelte-bg8huv"><!></div>');
const Ss = {
  hash: "svelte-bg8huv",
  code: ".root.svelte-bg8huv {width:360px;}.copy.svelte-bg8huv {display:flex;padding:40px 32px 70px 32px;flex-direction:column;align-items:center;justify-content:center;margin:auto;text-align:center;}.button.svelte-bg8huv {margin-top:16px;font-size:14px;text-decoration:underline;}h4.svelte-bg8huv {margin:24px 0 8px 0;font-weight:bold;}p.svelte-bg8huv {font-size:15px;color:var(--text-color-secondary);}"
};
function Cs(e, t) {
  te(t, !0), ie(e, Ss);
  let r = t.screenRecorder.getMediaDevices();
  r.catch((n) => {
    console.error("Error getting media devices", n);
  }), Yn(e, {
    get position() {
      return t.dialogPosition;
    },
    children: (n, o) => {
      var i = Ts(), s = k(i);
      pi(
        s,
        () => r,
        (u) => {
          var c = Rs(), l = k(c);
          xs(l, { size: 24 }), S(u, c);
        },
        (u, c) => {
          ws(u, {
            get devices() {
              return w(c);
            },
            get screenRecorder() {
              return t.screenRecorder;
            },
            get startRecording() {
              return t.startRecording;
            }
          });
        },
        (u) => {
          var c = ks(), l = O(k(c), 4);
          l.__click = [Es, t], S(u, c);
        }
      ), S(n, i);
    },
    $$slots: { default: !0 }
  }), re();
}
tt(["click"]);
var Ds = /* @__PURE__ */ q('<div class="root svelte-1fcxe0j"> </div>');
const Is = {
  hash: "svelte-1fcxe0j",
  code: ".root.svelte-1fcxe0j {width:40px;font-size:14px;font-weight:bold;}"
};
function Ps(e, t) {
  te(t, !0), ie(e, Is);
  let r = ee("00:00"), n = !1;
  Yt(() => {
    const s = () => {
      if (t.startTime) {
        const u = Date.now() - t.startTime;
        L(r, z(new Date(u).toISOString().substring(14, 19))), n || requestAnimationFrame(s);
      }
    };
    s();
  }), Mi(() => {
    n = !0;
  });
  var o = Ds(), i = k(o);
  ce(() => Lt(i, w(r))), S(e, o), re();
}
var or = { dragStart: !0 }, ir = { delay: 0, distance: 3 }, Ur = (e, t, r) => Math.min(Math.max(e, t), r), sr = (e) => typeof e == "string", Ms = ([e, t], r, n) => {
  const o = (i, s) => s === 0 ? 0 : Math.ceil(i / s) * s;
  return [o(r, e), o(n, t)];
}, jr = (e, t) => e.some((r) => t.some((n) => r.contains(n)));
function ar(e, t) {
  if (e === void 0) return;
  if (mr(e)) return e.getBoundingClientRect();
  if (typeof e == "object") {
    const { top: n = 0, left: o = 0, right: i = 0, bottom: s = 0 } = e;
    return { top: n, right: window.innerWidth - i, bottom: window.innerHeight - s, left: o };
  }
  if (e === "parent") return t.parentNode.getBoundingClientRect();
  const r = document.querySelector(e);
  if (r === null) throw new Error("The selector provided for bound doesn't exists in the document.");
  return r.getBoundingClientRect();
}
var Dt = (e, t, r) => e.style.setProperty(t, r), mr = (e) => e instanceof HTMLElement, lr = function(e, t = {}) {
  let r, n, { bounds: o, axis: i = "both", gpuAcceleration: s = !0, legacyTranslate: u = !1, transform: c, applyUserSelectHack: l = !0, disabled: a = !1, ignoreMultitouch: d = !1, recomputeBounds: f = or, grid: v, threshold: p = ir, position: m, cancel: g, handle: h, defaultClass: _ = "neodrag", defaultClassDragging: y = "neodrag-dragging", defaultClassDragged: x = "neodrag-dragged", defaultPosition: R = { x: 0, y: 0 }, onDragStart: T, onDrag: b, onDragEnd: P } = t, F = !1, j = !1, K = 0, ne = !1, de = !1, me = 0, _e = 0, G = 0, we = 0, rt = 0, nt = 0, { x: De, y: bt } = m ? { x: (m == null ? void 0 : m.x) ?? 0, y: (m == null ? void 0 : m.y) ?? 0 } : R;
  Qt(De, bt);
  let ye, Oe, ot, Gt, Jt, Tr = "", oo = !!m;
  f = { ...or, ...f }, p = { ...ir, ...p ?? {} };
  let xt = /* @__PURE__ */ new Set();
  function Sr() {
    F && !j && de && ne && Jt && (j = !0, $t("neodrag:start", T), Ie.add(y), l && (Tr = Zt.userSelect, Zt.userSelect = "none"));
  }
  const Zt = document.body.style, Ie = e.classList;
  function Qt(E = me, N = _e) {
    if (!c) {
      if (u) {
        let J = `${+E}px, ${+N}px`;
        return Dt(e, "transform", s ? `translate3d(${J}, 0)` : `translate(${J})`);
      }
      return Dt(e, "translate", `${+E}px ${+N}px`);
    }
    const B = c({ offsetX: E, offsetY: N, rootNode: e });
    sr(B) && Dt(e, "transform", B);
  }
  function $t(E, N) {
    const B = { offsetX: me, offsetY: _e, rootNode: e, currentNode: Jt };
    e.dispatchEvent(new CustomEvent(E, { detail: B })), N == null || N(B);
  }
  const Et = addEventListener, er = new AbortController(), tr = { signal: er.signal, capture: !1 };
  function Cr() {
    let E = e.offsetWidth / Oe.width;
    return isNaN(E) && (E = 1), E;
  }
  return Dt(e, "touch-action", "none"), Et("pointerdown", (E) => {
    if (a || E.button === 2) return;
    if (xt.add(E.pointerId), d && xt.size > 1) return E.preventDefault();
    if (f.dragStart && (ye = ar(o, e)), sr(h) && sr(g) && h === g) throw new Error("`handle` selector can't be same as `cancel` selector");
    if (Ie.add(_), ot = function(M, se) {
      if (!M) return [se];
      if (mr(M)) return [M];
      if (Array.isArray(M)) return M;
      const be = se.querySelectorAll(M);
      if (be === null) throw new Error("Selector passed for `handle` option should be child of the element on which the action is applied");
      return Array.from(be.values());
    }(h, e), Gt = function(M, se) {
      if (!M) return [];
      if (mr(M)) return [M];
      if (Array.isArray(M)) return M;
      const be = se.querySelectorAll(M);
      if (be === null) throw new Error("Selector passed for `cancel` option should be child of the element on which the action is applied");
      return Array.from(be.values());
    }(g, e), r = /(both|x)/.test(i), n = /(both|y)/.test(i), jr(Gt, ot)) throw new Error("Element being dragged can't be a child of the element on which `cancel` is applied");
    const N = E.composedPath()[0];
    if (!ot.some((M) => {
      var se;
      return M.contains(N) || ((se = M.shadowRoot) == null ? void 0 : se.contains(N));
    }) || jr(Gt, [N])) return;
    Jt = ot.length === 1 ? e : ot.find((M) => M.contains(N)), F = !0, K = Date.now(), p.delay || (ne = !0), Oe = e.getBoundingClientRect();
    const { clientX: B, clientY: J } = E, Z = Cr();
    r && (G = B - De / Z), n && (we = J - bt / Z), ye && (rt = B - Oe.left, nt = J - Oe.top);
  }, tr), Et("pointermove", (E) => {
    if (!F || d && xt.size > 1) return;
    if (!j) {
      if (ne || Date.now() - K >= p.delay && (ne = !0, Sr()), !de) {
        const Z = E.clientX - G, M = E.clientY - we;
        Math.sqrt(Z ** 2 + M ** 2) >= p.distance && (de = !0, Sr());
      }
      if (!j) return;
    }
    f.drag && (ye = ar(o, e)), E.preventDefault(), Oe = e.getBoundingClientRect();
    let N = E.clientX, B = E.clientY;
    const J = Cr();
    if (ye) {
      const Z = { left: ye.left + rt, top: ye.top + nt, right: ye.right + rt - Oe.width, bottom: ye.bottom + nt - Oe.height };
      N = Ur(N, Z.left, Z.right), B = Ur(B, Z.top, Z.bottom);
    }
    if (Array.isArray(v)) {
      let [Z, M] = v;
      if (isNaN(+Z) || Z < 0) throw new Error("1st argument of `grid` must be a valid positive number");
      if (isNaN(+M) || M < 0) throw new Error("2nd argument of `grid` must be a valid positive number");
      let se = N - G, be = B - we;
      [se, be] = Ms([Z / J, M / J], se, be), N = G + se, B = we + be;
    }
    r && (me = Math.round((N - G) * J)), n && (_e = Math.round((B - we) * J)), De = me, bt = _e, $t("neodrag", b), Qt();
  }, tr), Et("pointerup", (E) => {
    xt.delete(E.pointerId), F && (j && (Et("click", (N) => N.stopPropagation(), { once: !0, signal: er.signal, capture: !0 }), f.dragEnd && (ye = ar(o, e)), Ie.remove(y), Ie.add(x), l && (Zt.userSelect = Tr), $t("neodrag:end", P), r && (G = me), n && (we = _e)), F = !1, j = !1, ne = !1, de = !1);
  }, tr), { destroy: () => er.abort(), update: (E) => {
    var B, J;
    i = E.axis || "both", a = E.disabled ?? !1, d = E.ignoreMultitouch ?? !1, h = E.handle, o = E.bounds, f = E.recomputeBounds ?? or, g = E.cancel, l = E.applyUserSelectHack ?? !0, v = E.grid, s = E.gpuAcceleration ?? !0, u = E.legacyTranslate ?? !0, c = E.transform, p = { ...ir, ...E.threshold ?? {} };
    const N = Ie.contains(x);
    Ie.remove(_, x), _ = E.defaultClass ?? "neodrag", y = E.defaultClassDragging ?? "neodrag-dragging", x = E.defaultClassDragged ?? "neodrag-dragged", Ie.add(_), N && Ie.add(x), oo && (De = me = ((B = E.position) == null ? void 0 : B.x) ?? me, bt = _e = ((J = E.position) == null ? void 0 : J.y) ?? _e, Qt());
  } };
}, Ls = /* @__PURE__ */ q('<div class="handle svelte-bqwgm2"><div class="preview svelte-bqwgm2"><!></div></div>'), As = /* @__PURE__ */ Ce('<circle cx="9" cy="12" r="1"></circle><circle cx="9" cy="5" r="1"></circle><circle cx="9" cy="19" r="1"></circle><circle cx="15" cy="12" r="1"></circle><circle cx="15" cy="5" r="1"></circle><circle cx="15" cy="19" r="1"></circle>', 1), Ns = (e, t) => t.stopRecording(), qs = (e, t) => t.abortRecording(), Os = /* @__PURE__ */ Ce('><path d="M3 6h18"></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path><line x1="10" x2="10" y1="11" y2="17"></line><line x1="14" x2="14" y1="11" y2="17"></line>', 1), zs = /* @__PURE__ */ q('<div class="root svelte-bqwgm2"><!> <div class="footer svelte-bqwgm2"><button class="grip handle svelte-bqwgm2"><!></button> <div class="controls svelte-bqwgm2"><!> <button class="control-button svelte-bqwgm2" aria-label="Stop recording"><span class="stop svelte-bqwgm2"></span></button> <button class="control-button svelte-bqwgm2" aria-label="Cancel recording"><!></button></div></div></div>');
const Hs = {
  hash: "svelte-bqwgm2",
  code: ".neo-drag-dragging .handle.svelte-bqwgm2 {cursor:grabbing;}.handle.svelte-bqwgm2 {cursor:grab;}.preview.svelte-bqwgm2 {pointer-events:none;}.root.svelte-bqwgm2 {position:fixed;right:20px;top:20px;width:200px;background:var(--background-primary);border-radius:8px;box-shadow:rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;overflow:hidden;user-select:none;}.footer.svelte-bqwgm2 {display:grid;grid-template-columns:auto 1fr;}button.svelte-bqwgm2 {display:flex;align-items:center;justify-content:center;transition:all 0.1s ease-out;&:hover {background:rgba(0, 0, 0, 0.05);}}.grip.svelte-bqwgm2 {width:40px;height:48px;border-right:solid 1px var(--color-border);}.stop.svelte-bqwgm2 {display:block;width:14px;height:14px;background:red;border-radius:2px;}.controls.svelte-bqwgm2 {display:grid;grid-template-columns:1fr auto auto;align-items:center;gap:2px;padding:8px 8px 8px 16px;}.control-button.svelte-bqwgm2 {width:32px;height:32px;border-radius:4px;}"
};
function Fs(e, t) {
  te(t, !0), ie(e, Hs);
  var r = zs();
  H(r, "z-index", Wt);
  var n = k(r);
  {
    var o = (v) => {
      var p = Ls(), m = k(p), g = k(m);
      Wn(g, {
        get videoDeviceId() {
          return t.videoDeviceId;
        },
        get screenRecorder() {
          return t.screenRecorder;
        }
      }), S(v, p);
    };
    ae(n, (v) => {
      t.videoDeviceId && v(o);
    });
  }
  var i = O(n, 2), s = k(i), u = k(s);
  Me(u, {
    children: (v, p) => {
      var m = As();
      S(v, m);
    },
    $$slots: { default: !0 }
  });
  var c = O(s, 2), l = k(c);
  Ps(l, {
    get startTime() {
      return t.startTime;
    }
  });
  var a = O(l, 2);
  a.__click = [Ns, t];
  var d = O(a, 2);
  d.__click = [qs, t];
  var f = k(d);
  Me(f, {
    size: 16,
    children: (v, p) => {
      var m = Os();
      S(v, m);
    },
    $$slots: { default: !0 }
  }), xi(r, (v, p) => lr == null ? void 0 : lr(v, p), () => ({ bounds: "body", handle: ".handle" })), ce(() => H(r, "border", t.videoDeviceId ? "none" : "solid 1px var(--color-border)")), S(e, r), re();
}
tt(["click"]);
const Bs = "0.2.0";
var pe;
(function(e) {
  e.Call = "call", e.Reply = "reply", e.Syn = "syn", e.SynAck = "synAck", e.Ack = "ack";
})(pe || (pe = {}));
var Fe;
(function(e) {
  e.Fulfilled = "fulfilled", e.Rejected = "rejected";
})(Fe || (Fe = {}));
var vt;
(function(e) {
  e.ConnectionDestroyed = "ConnectionDestroyed", e.ConnectionTimeout = "ConnectionTimeout", e.NoIframeSrc = "NoIframeSrc";
})(vt || (vt = {}));
var _r;
(function(e) {
  e.DataCloneError = "DataCloneError";
})(_r || (_r = {}));
var qe;
(function(e) {
  e.Message = "message";
})(qe || (qe = {}));
const Vs = (e, t) => {
  const r = [];
  let n = !1;
  return {
    destroy(o) {
      n || (n = !0, t(`${e}: Destroying connection`), r.forEach((i) => {
        i(o);
      }));
    },
    onDestroy(o) {
      n ? o() : r.push(o);
    }
  };
}, Us = (e) => (...t) => {
  e && console.log("[Penpal]", ...t);
}, js = {
  "http:": "80",
  "https:": "443"
}, Ks = /^(https?:)?\/\/([^/:]+)?(:(\d+))?/, Ys = ["file:", "data:"], Ws = (e) => {
  if (e && Ys.find((u) => e.startsWith(u)))
    return "null";
  const t = document.location, r = Ks.exec(e);
  let n, o, i;
  r ? (n = r[1] ? r[1] : t.protocol, o = r[2], i = r[4]) : (n = t.protocol, o = t.hostname, i = t.port);
  const s = i && i !== js[n] ? `:${i}` : "";
  return `${n}//${o}${s}`;
}, Kr = ({ name: e, message: t, stack: r }) => ({
  name: e,
  message: t,
  stack: r
}), Xs = (e) => {
  const t = new Error();
  return Object.keys(e).forEach((r) => t[r] = e[r]), t;
}, Gs = (e, t, r) => {
  const { localName: n, local: o, remote: i, originForSending: s, originForReceiving: u } = e;
  let c = !1;
  const l = (a) => {
    if (a.source !== i || a.data.penpal !== pe.Call)
      return;
    if (u !== "*" && a.origin !== u) {
      r(`${n} received message from origin ${a.origin} which did not match expected origin ${u}`);
      return;
    }
    const d = a.data, { methodName: f, args: v, id: p } = d;
    r(`${n}: Received ${f}() call`);
    const m = (g) => (h) => {
      if (r(`${n}: Sending ${f}() reply`), c) {
        r(`${n}: Unable to send ${f}() reply due to destroyed connection`);
        return;
      }
      const _ = {
        penpal: pe.Reply,
        id: p,
        resolution: g,
        returnValue: h
      };
      g === Fe.Rejected && h instanceof Error && (_.returnValue = Kr(h), _.returnValueIsError = !0);
      try {
        i.postMessage(_, s);
      } catch (y) {
        if (y.name === _r.DataCloneError) {
          const x = {
            penpal: pe.Reply,
            id: p,
            resolution: Fe.Rejected,
            returnValue: Kr(y),
            returnValueIsError: !0
          };
          i.postMessage(x, s);
        }
        throw y;
      }
    };
    new Promise((g) => g(t[f].apply(t, v))).then(m(Fe.Fulfilled), m(Fe.Rejected));
  };
  return o.addEventListener(qe.Message, l), () => {
    c = !0, o.removeEventListener(qe.Message, l);
  };
};
let Js = 0;
const Zs = () => ++Js, Xn = ".", Gn = (e) => e ? e.split(Xn) : [], Qs = (e) => e.join(Xn), $s = (e, t) => {
  const r = Gn(t || "");
  return r.push(e), Qs(r);
}, ea = (e, t, r) => {
  const n = Gn(t);
  return n.reduce((o, i, s) => (typeof o[i] > "u" && (o[i] = {}), s === n.length - 1 && (o[i] = r), o[i]), e), e;
}, Jn = (e, t) => {
  const r = {};
  return Object.keys(e).forEach((n) => {
    const o = e[n], i = $s(n, t);
    typeof o == "object" && Object.assign(r, Jn(o, i)), typeof o == "function" && (r[i] = o);
  }), r;
}, ta = (e) => {
  const t = {};
  for (const r in e)
    ea(t, r, e[r]);
  return t;
}, ra = (e, t, r, n, o) => {
  const { localName: i, local: s, remote: u, originForSending: c, originForReceiving: l } = t;
  let a = !1;
  o(`${i}: Connecting call sender`);
  const d = (v) => (...p) => {
    o(`${i}: Sending ${v}() call`);
    let m;
    try {
      u.closed && (m = !0);
    } catch {
      m = !0;
    }
    if (m && n(), a) {
      const g = new Error(`Unable to send ${v}() call due to destroyed connection`);
      throw g.code = vt.ConnectionDestroyed, g;
    }
    return new Promise((g, h) => {
      const _ = Zs(), y = (R) => {
        if (R.source !== u || R.data.penpal !== pe.Reply || R.data.id !== _)
          return;
        if (l !== "*" && R.origin !== l) {
          o(`${i} received message from origin ${R.origin} which did not match expected origin ${l}`);
          return;
        }
        const T = R.data;
        o(`${i}: Received ${v}() reply`), s.removeEventListener(qe.Message, y);
        let b = T.returnValue;
        T.returnValueIsError && (b = Xs(b)), (T.resolution === Fe.Fulfilled ? g : h)(b);
      };
      s.addEventListener(qe.Message, y);
      const x = {
        penpal: pe.Call,
        id: _,
        methodName: v,
        args: p
      };
      u.postMessage(x, c);
    });
  }, f = r.reduce((v, p) => (v[p] = d(p), v), {});
  return Object.assign(e, ta(f)), () => {
    a = !0;
  };
}, na = (e, t, r, n, o) => {
  const { destroy: i, onDestroy: s } = n;
  let u, c;
  const l = {};
  return (a) => {
    if (t !== "*" && a.origin !== t) {
      o(`Parent: Handshake - Received ACK message from origin ${a.origin} which did not match expected origin ${t}`);
      return;
    }
    o("Parent: Handshake - Received ACK");
    const d = {
      localName: "Parent",
      local: window,
      remote: a.source,
      originForSending: r,
      originForReceiving: t
    };
    u && u(), u = Gs(d, e, o), s(u), c && c.forEach((v) => {
      delete l[v];
    }), c = a.data.methodNames;
    const f = ra(l, d, c, i, o);
    return s(f), l;
  };
}, oa = (e, t, r, n) => (o) => {
  if (!o.source)
    return;
  if (r !== "*" && o.origin !== r) {
    e(`Parent: Handshake - Received SYN message from origin ${o.origin} which did not match expected origin ${r}`);
    return;
  }
  e("Parent: Handshake - Received SYN, responding with SYN-ACK");
  const i = {
    penpal: pe.SynAck,
    methodNames: Object.keys(t)
  };
  o.source.postMessage(i, n);
}, ia = 6e4, sa = (e, t) => {
  const { destroy: r, onDestroy: n } = t, o = setInterval(() => {
    e.isConnected || (clearInterval(o), r());
  }, ia);
  n(() => {
    clearInterval(o);
  });
}, aa = (e, t) => {
  let r;
  return e !== void 0 && (r = window.setTimeout(() => {
    const n = new Error(`Connection timed out after ${e}ms`);
    n.code = vt.ConnectionTimeout, t(n);
  }, e)), () => {
    clearTimeout(r);
  };
}, la = (e) => {
  if (!e.src && !e.srcdoc) {
    const t = new Error("Iframe must have src or srcdoc property defined.");
    throw t.code = vt.NoIframeSrc, t;
  }
}, ca = (e) => {
  let { iframe: t, methods: r = {}, childOrigin: n, timeout: o, debug: i = !1 } = e;
  const s = Us(i), u = Vs("Parent", s), { onDestroy: c, destroy: l } = u;
  n || (la(t), n = Ws(t.src));
  const a = n === "null" ? "*" : n, d = Jn(r), f = oa(s, d, n, a), v = na(d, n, a, u, s);
  return {
    promise: new Promise((m, g) => {
      const h = aa(o, l), _ = (y) => {
        if (!(y.source !== t.contentWindow || !y.data)) {
          if (y.data.penpal === pe.Syn) {
            f(y);
            return;
          }
          if (y.data.penpal === pe.Ack) {
            const x = v(y);
            x && (h(), m(x));
            return;
          }
        }
      };
      window.addEventListener(qe.Message, _), s("Parent: Awaiting handshake"), sa(t, u), c((y) => {
        window.removeEventListener(qe.Message, _), y && g(y);
      });
    }),
    destroy() {
      l();
    }
  };
}, ua = () => {
  var e;
  return ((e = document.querySelector('meta[name="description"]')) == null ? void 0 : e.getAttribute("content")) || "";
};
var da = /* @__PURE__ */ q('<div class="root svelte-un9icw"><div class="overlay svelte-un9icw"></div> <iframe allow="clipboard-write" title="Send capture" allowtransparency="" class="svelte-un9icw"></iframe></div>');
const fa = {
  hash: "svelte-un9icw",
  code: ".root.svelte-un9icw {position:fixed;width:100vw;height:100vh;left:0;top:0;}.overlay.svelte-un9icw {position:absolute;left:0;top:0;width:100%;height:100%;transition:0.3s ease-out;background:rgba(0, 0, 0, 0.5);}iframe.svelte-un9icw {background:transparent;transition:transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);position:absolute;left:0;top:0;width:100%;height:100%;border:none;}"
};
function va(e, t) {
  te(t, !0), ie(e, fa);
  let r, n = ee(!1);
  const o = {
    onReportUploaded: (l) => {
      t.onReportUploaded(l);
    },
    getAttachment: () => {
      if (t.attachment)
        return hi(t.attachment);
      throw new Error("No attachment");
    },
    getClientInfo: () => ({
      captureKey: t.captureKey,
      teamId: t.teamId,
      url: window.location.href,
      source: t.source,
      version: Bs,
      pageTitle: document.title,
      pageDescription: ua(),
      graphQLEndpoint: t.graphQLEndpoint
    }),
    close: t.close
  };
  let i = ee(void 0);
  Yt(async () => {
    const l = ca({ iframe: r, methods: o, timeout: 1e4 });
    try {
      L(i, z(await l.promise));
    } catch (a) {
      a = !0;
    }
  }), br(() => {
    t.attachment && w(i) && w(i).setData({
      attachment: o.getAttachment(),
      clientInfo: o.getClientInfo()
    }).then(() => {
      L(n, !0);
    }).catch(() => {
      t.close();
    });
  });
  var s = da();
  H(s, "z-index", Wt);
  var u = k(s), c = O(u, 2);
  Vn(c, (l) => r = l, () => r), ce(() => {
    H(s, "pointer-events", w(n) ? "auto" : "none"), H(u, "opacity", w(n) ? 1 : 0), Ot(c, "src", t.widgetUrl), H(c, "visibility", w(n) ? "visible" : "hidden"), H(c, "transform", w(n) ? "scale(1)" : "scale(0.95)");
  }), S(e, s), re();
}
const ha = (e) => {
  const t = new Image();
  return new Promise((r, n) => {
    t.onload = () => {
      r(t);
    }, t.onerror = (o) => {
      n(o);
    }, t.src = e;
  });
};
async function pa(e, t) {
  const r = await ha(e.dataUrl), n = r.naturalWidth / e.tabWidth;
  return new Promise((o, i) => {
    const s = document.createElement("canvas");
    s.width = t.width * n, s.height = t.height * n;
    const u = s.getContext("2d");
    if (!u) {
      i(new Error("Failed to get canvas context."));
      return;
    }
    u.drawImage(
      r,
      -t.x * n,
      -t.y * n,
      r.naturalWidth,
      r.naturalHeight
    );
    const c = s.toDataURL("image/png", 1);
    o(c);
  });
}
const ga = async (e) => (await (await fetch(e)).blob()).arrayBuffer();
var ma = /* @__PURE__ */ q("<!> <!>", 1);
const _a = {
  hash: "svelte-1jc7sg4",
  code: ':host {--background-secondary: #f6f6f7;--background-primary: #fff;--background-tertiary: #ecedf1;--background-contrast: #111;--text-color-default: #111111;--text-color-secondary: #6b7085;--text-color-contrast: #fff;--color-border: #eaeaea;--button-primary-bg: #111;--button-primary-text-color: #fff;--button-primary-border: none;--button-secondary-bg: var(--background-primary);--button-secondary-border: solid 1px var(--color-border);--button-secondary-text-color: #111111;--button-secondary-bg-hover: #fafafa;all:initial; /* Prevent host styles leaking */font-family:"Figtree", sans-serif;font-size:16px;line-height:1.2;font-weight:normal;color:var(--text-color-default);color-scheme:light dark; /* Enables iframe transparency on some websites in dark mode */}* {box-sizing:border-box;}button {font:inherit;appearance:none;background:transparent;border:0;outline:0;cursor:pointer;color:inherit;padding:0;}p, h4 {margin:0;}'
};
function wa(e, t) {
  te(t, !0), ie(e, _a);
  const r = Kt(t, "initialView", 19, () => ({ name: "initial" }));
  let n = ee(z(r())), o = ee(!1);
  function i(h) {
    L(n, z(h));
  }
  Nr("close", t.close);
  let s = ee(!0);
  Nr("isInitialRender", () => w(s)), Yt(() => {
    L(s, !1);
  });
  async function u(h) {
    try {
      L(o, !0), await new Promise((x) => setTimeout(x, 100));
      const _ = await t.screenshotTaker.takeScreenshot();
      L(o, !1);
      let y = _.dataUrl;
      h && (y = await pa(_, h)), i({
        name: "send-preview",
        attachment: {
          type: "screenshot",
          dataUrl: y,
          events: _.events
        }
      });
    } catch (_) {
      console.error("Failed to take screenshot", _);
    }
  }
  function c() {
    console.error("Error recording screen");
  }
  t.screenRecorder.on("complete", async (h) => {
    let _;
    h.blob instanceof Blob ? _ = await h.blob.arrayBuffer() : _ = await ga(h.objectUrl), i({
      name: "send-preview",
      attachment: {
        type: "recording",
        arrayBuffer: _,
        events: h.events,
        startTime: h.startTime,
        endTime: h.endTime,
        fileType: h.fileType
      }
    });
  });
  async function l(h) {
    try {
      t.screenRecorder.on("error", c);
      const _ = await t.screenRecorder.startRecording(h);
      i({
        name: "screen-recording-controls",
        startTime: _,
        videoDeviceId: h == null ? void 0 : h.videoDeviceId
      });
    } catch (_) {
      console.error("Error starting screen recording", _), i({ name: "initial", initialTab: "recording" });
    }
  }
  async function a() {
    try {
      await t.screenRecorder.stopRecording();
    } catch (h) {
      console.error("Error stopping screen recording", h);
    }
  }
  function d() {
    t.screenRecorder.abortRecording(), i({ name: "initial", initialTab: "recording" });
  }
  var f = ma(), v = ze(f);
  {
    var p = (h) => {
      var _ = Xe(), y = ze(_);
      {
        var x = (T) => {
          var b = /* @__PURE__ */ ke(() => t.screenshotTaker.canCaptureArea());
          ns(T, {
            setView: i,
            get dialogPosition() {
              return t.position;
            },
            get initialTab() {
              return w(n).initialTab;
            },
            takeScreenshot: u,
            startRecording: l,
            get canCaptureArea() {
              return w(b);
            }
          });
        }, R = (T) => {
          var b = Xe(), P = ze(b);
          {
            var F = (K) => {
              ls(K, {
                get initialPointer() {
                  return w(n).initialPointer;
                },
                takeScreenshot: u,
                setView: i
              });
            }, j = (K) => {
              var ne = Xe(), de = ze(ne);
              {
                var me = (G) => {
                  Cs(G, {
                    get dialogPosition() {
                      return t.position;
                    },
                    get screenRecorder() {
                      return t.screenRecorder;
                    },
                    startRecording: l,
                    setView: i
                  });
                }, _e = (G) => {
                  var we = Xe(), rt = ze(we);
                  {
                    var nt = (De) => {
                      Fs(De, {
                        get startTime() {
                          return w(n).startTime;
                        },
                        stopRecording: a,
                        get screenRecorder() {
                          return t.screenRecorder;
                        },
                        abortRecording: d,
                        get videoDeviceId() {
                          return w(n).videoDeviceId;
                        }
                      });
                    };
                    ae(
                      rt,
                      (De) => {
                        w(n).name === "screen-recording-controls" && De(nt);
                      },
                      !0
                    );
                  }
                  S(G, we);
                };
                ae(
                  de,
                  (G) => {
                    w(n).name === "screen-recording-options" ? G(me) : G(_e, !1);
                  },
                  !0
                );
              }
              S(K, ne);
            };
            ae(
              P,
              (K) => {
                w(n).name === "capture-area" ? K(F) : K(j, !1);
              },
              !0
            );
          }
          S(T, b);
        };
        ae(y, (T) => {
          w(n).name === "initial" ? T(x) : T(R, !1);
        });
      }
      S(h, _);
    };
    ae(v, (h) => {
      w(o) || h(p);
    });
  }
  var m = O(v, 2), g = /* @__PURE__ */ ke(() => w(n).name === "send-preview" ? w(n).attachment : null);
  va(m, {
    get widgetUrl() {
      return t.widgetUrl;
    },
    get source() {
      return t.source;
    },
    get captureKey() {
      return t.captureKey;
    },
    get teamId() {
      return t.teamId;
    },
    get close() {
      return t.close;
    },
    get onReportUploaded() {
      return t.onReportUploaded;
    },
    get attachment() {
      return w(g);
    }
  }), S(e, f), re();
}
class ol extends Un {
  constructor(r) {
    super();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    D(this, "app", null);
    D(this, "setOptions", (r) => {
      this.options = { ...this.options, ...r };
    });
    D(this, "handleReportUploaded", (r) => {
      var n, o;
      (o = (n = this.options).onReportUploaded) == null || o.call(n, r);
    });
    this.options = r, r.handleKeyboardShortcut && document.addEventListener("keydown", (n) => {
      var i;
      ((i = r.handleKeyboardShortcut) == null ? void 0 : i.call(r, n)) && this.open();
    });
  }
  get isOpen() {
    return this.app !== null;
  }
  get isDisabled() {
    return typeof this.options.isDisabled == "function" ? this.options.isDisabled() : this.options.isDisabled ?? !1;
  }
  open(r = {}) {
    if (this.isDisabled)
      return;
    if (!this.options.teamId && !this.options.captureKey)
      throw new Error(
        "Either captureKey or teamId must be provided to open the capture widget"
      );
    customElements instanceof CustomElementRegistry && !customElements.get("capture-client") && customElements.define("capture-client", class extends HTMLElement {
    });
    let n = document.querySelector("capture-client");
    n || (n = document.createElement("capture-client"), document.body.appendChild(n));
    let o = n.shadowRoot;
    o || (o = n.attachShadow({ mode: "open" })), this.app = ui(wa, {
      intro: !0,
      target: o,
      props: {
        teamId: this.options.teamId,
        initialView: r.initialView,
        position: this.options.position,
        screenRecorder: this.options.screenRecorder,
        screenshotTaker: this.options.screenshotTaker,
        widgetUrl: this.options.widgetUrl,
        graphQLEndpoint: Li(this.options.graphQLEndpoint),
        captureKey: this.options.captureKey,
        source: this.options.source,
        close: () => this.close(),
        onReportUploaded: this.handleReportUploaded
      }
    });
  }
  close() {
    this.app && (fi(this.app, { outro: !0 }), this.app = null);
  }
}
const Xt = (e) => ({
  name: e.name,
  message: e.message,
  stack: e.stack,
  cause: e.cause instanceof Error ? Xt(e.cause) : null
}), Yr = console.log, ya = console.warn, ba = Object.prototype.hasOwnProperty;
function Zn(e) {
  return "[Throws: " + (e instanceof Error ? e.message : "?") + "]";
}
function xa(e, t) {
  if (ba.call(e, t))
    try {
      return e[t];
    } catch (r) {
      return Zn(r);
    }
  return e[t];
}
function Wr(e, t = {}) {
  const r = [], n = t.maxDepth ?? 20, o = t.maxStringLength ?? 1e5, i = t.maxSize ?? 5e5;
  let s = 0, u = 0, c = !1;
  function l(d) {
    if (u > i)
      return c = !0, !1;
    if (s > n)
      return "[Depth limit reached]";
    if (d === null || typeof d == "boolean" || typeof d == "number" || typeof d == "string") {
      const f = JSON.stringify(d).length;
      return u += f, typeof d == "string" && f > o ? d.substring(0, o) + " [Output truncated due to length]" : d;
    }
    if (r.indexOf(d) !== -1)
      return "[Circular]";
    if (r.push(d), Array.isArray(d)) {
      const f = d.map(l);
      return r.pop(), f;
    }
    if (typeof d == "object") {
      if (s++, "toJSON" in d && typeof d.toJSON == "function")
        try {
          const v = l(d.toJSON());
          return r.pop(), v;
        } catch (v) {
          return Zn(v);
        }
      const f = Object.keys(d).reduce(
        function(v, p) {
          return v[p] = l(xa(d, p)), v;
        },
        {}
      );
      return r.pop(), f;
    }
    return typeof d == "function" ? "[Function]" : "[Unknown]";
  }
  const a = l(e);
  return c ? "[Object is too large]" : a;
}
let Ea = 0;
function $() {
  return `${Ea++}`;
}
const ka = ["log", "info", "warn", "error", "debug"], Xr = (e) => ({
  __CAPTURE_TYPE__: "error",
  error: Xt(e)
});
class Ra {
  constructor(t) {
    D(this, "unsetters", []);
    D(this, "onErrorHandler", (t) => {
      this.controller.recordEvent({
        id: $(),
        time: Date.now(),
        type: "console",
        method: "error",
        args: [
          t.error instanceof Error ? Xr(t.error) : Wr(t.error)
        ],
        extra: {
          originalArgs: [t.error]
        }
      });
    });
    D(this, "onPromiseRejectionHandler", (t) => {
      Yr(t);
    });
    D(this, "onMessageErrorHandler", (t) => {
      Yr(t);
    });
    D(this, "onSecurityPolicyViolationHandler", (t) => {
      const r = [
        `Refused to load the resource '${t.blockedURI}' because it violates the following Content Security Policy directive: '${t.violatedDirective}'.`
      ];
      this.controller.recordEvent({
        id: $(),
        time: Date.now(),
        type: "console",
        method: "error",
        args: r,
        extra: {
          originalArgs: r
        }
      });
    });
    this.controller = t;
  }
  start() {
    this.unsetters = [];
    for (const t of ka) {
      const r = this.controller.recordEvent, n = console[t];
      console[t] = function(...o) {
        return r({
          id: $(),
          type: "console",
          time: Date.now(),
          method: t,
          extra: {
            originalArgs: o
          },
          args: o.map((i) => i instanceof Error ? Xr(i) : Wr(i))
        }), n(...o);
      }, this.unsetters.push(() => {
        console[t] = n;
      });
    }
    window.addEventListener("error", this.onErrorHandler), window.addEventListener(
      "unhandledrejection",
      this.onPromiseRejectionHandler
    ), window.addEventListener("messageerror", this.onMessageErrorHandler), window.addEventListener(
      "securitypolicyviolation",
      this.onSecurityPolicyViolationHandler
    );
  }
  stop() {
    window.removeEventListener("error", this.onErrorHandler), window.removeEventListener(
      "unhandledrejection",
      this.onPromiseRejectionHandler
    ), window.removeEventListener("messageerror", this.onMessageErrorHandler), window.removeEventListener(
      "securitypolicyviolation",
      this.onSecurityPolicyViolationHandler
    ), this.unsetters.forEach((t) => t());
  }
}
const Gr = (e) => {
  let t = e.substring(0, 300);
  return e.length != t.length && (t += "…"), t;
}, Ta = ["input", "select", "textarea"], Sa = [
  "id",
  "name",
  "class",
  "type",
  "placeholder",
  "role",
  "href",
  "data-testid"
], Qn = (e) => e.length > 200 ? e.slice(0, 200) + "…" : e, Ca = (e) => {
  const t = {};
  return Sa.forEach((r) => {
    var o;
    const n = (o = e.getAttribute(r)) == null ? void 0 : o.trim();
    n && (t[r] = Qn(n));
  }), t;
}, $n = (e) => {
  var o;
  const t = e.getAttribute("aria-labelledby");
  if (t) {
    const i = document.getElementById(t);
    if (i)
      return i.textContent ?? void 0;
  }
  const r = e.getAttribute("aria-label");
  if (r)
    return r.trim();
  const n = e.tagName.toLowerCase();
  if (Ta.includes(n)) {
    const i = e.getAttribute("id");
    if (i) {
      const u = document.querySelector(`label[for="${i}"]`);
      if (u)
        return $n(u);
    }
    const s = e.getAttribute("placeholder");
    if (s)
      return s.trim();
  }
  if (e.matches("button,[role=button],a,[role=link],option,[role=option]"))
    return ((o = e.textContent) == null ? void 0 : o.trim()) ?? void 0;
}, Da = "button, a, [role=button], [role=link]", It = (e) => {
  const r = e.closest(Da) ?? e, n = $n(r);
  return {
    tagName: r.tagName.toLowerCase(),
    name: n ? Qn(n) : void 0,
    attributes: Ca(r)
  };
}, Ia = ["input", "textarea", "[contenteditable=true]"], eo = (e = document) => {
  const t = e.activeElement;
  return t != null && t.shadowRoot ? eo(t.shadowRoot) : null;
}, it = (e) => e.target instanceof Element && e.target.tagName.toLowerCase() === "capture-widget", Jr = (e) => e instanceof Element && Ia.some((t) => e.matches(t)), Pa = (e) => e instanceof HTMLElement && e.matches(
  "button, a, input, textarea, select, option, [contenteditable=true], [tabindex], [role=button], [role=link], [role=checkbox], [role=radio], [role=menuitem], [role=menuitemcheckbox], [role=menuitemradio]"
), Ma = (e) => "value" in e && typeof e.value == "string" ? Gr(e.value) : e instanceof HTMLElement ? Gr(e.innerText) : "";
class La {
  constructor(t) {
    D(this, "lastFocusElement", null);
    D(this, "resizeTimeout", null);
    D(this, "interval", null);
    D(this, "previousHref", "");
    D(this, "inputMethod", "mouse");
    D(this, "onSubmit", (t) => {
      if (it(t))
        return;
      const r = t.composedPath()[0];
      r instanceof HTMLFormElement && this.controller.recordEvent({
        id: $(),
        time: Date.now(),
        type: "submit",
        element: It(r),
        extra: { target: r }
      });
    });
    D(this, "onPointerDown", (t) => {
      if (it(t))
        return;
      this.inputMethod = "mouse";
      const r = t.composedPath()[0];
      Pa(r) && this.controller.recordEvent({
        id: $(),
        time: Date.now(),
        type: "click",
        pointer: t.pointerType,
        element: It(r),
        extra: { target: r }
      });
    });
    D(this, "onFocus", (t) => {
      if (it(t))
        return;
      const r = t.composedPath()[0];
      r instanceof Element && this.inputMethod === "keyboard" && this.onElementFocussed(r);
    });
    D(this, "onKeyDown", (t) => {
      if (it(t))
        return;
      t.key === "Tab" && (this.inputMethod = "keyboard");
      const r = t.composedPath()[0];
      if (t.key === "Tab" && r === this.lastFocusElement) {
        this.lastFocusElement = null;
        return;
      }
      !Jr(r) && !["Meta", "Shift", "Command", "Alt"].includes(t.key) && this.controller.recordEvent({
        id: $(),
        time: Date.now(),
        type: "keypress",
        key: t.key,
        meta: t.metaKey,
        ctrl: t.ctrlKey,
        shift: t.shiftKey,
        alt: t.altKey
      });
    });
    D(this, "onResize", () => {
      this.resizeTimeout && clearTimeout(this.resizeTimeout), this.resizeTimeout = setTimeout(() => {
        this.onWindowResized(), this.resizeTimeout = null;
      }, 500);
    });
    D(this, "onChange", (t) => {
      if (it(t))
        return;
      const r = t.composedPath()[0];
      if (r instanceof Element) {
        const n = Ma(r);
        this.controller.recordEvent({
          id: $(),
          time: Date.now(),
          type: "change",
          value: n,
          element: It(r),
          extra: { target: r }
        });
      }
    });
    D(this, "onInterval", () => {
      window.location.href !== this.previousHref && (this.controller.recordEvent({
        id: $(),
        time: Date.now(),
        type: "navigation",
        url: window.location.href
      }), this.previousHref = window.location.href);
    });
    D(this, "onWindowResized", () => {
      this.controller.recordEvent({
        id: $(),
        time: Date.now(),
        type: "resize",
        width: window.innerWidth,
        height: window.innerHeight
      });
    });
    D(this, "onElementFocussed", (t) => {
      this.controller.recordEvent({
        id: $(),
        time: Date.now(),
        type: "focus",
        element: It(t),
        extra: { target: t }
      });
    });
    this.controller = t;
  }
  start() {
    window.addEventListener("pointerdown", this.onPointerDown, !0), window.addEventListener("submit", this.onSubmit, !0), window.addEventListener("keydown", this.onKeyDown, !0), window.addEventListener("focus", this.onFocus, !0), window.addEventListener("resize", this.onResize, !0), window.addEventListener("change", this.onChange, !0), this.previousHref = window.location.href, this.interval = setInterval(this.onInterval, 50);
    const t = eo();
    t && Jr(t) && this.onElementFocussed(t);
  }
  stop() {
    window.removeEventListener("pointerdown", this.onPointerDown, !0), window.removeEventListener("keydown", this.onKeyDown, !0), window.removeEventListener("focus", this.onFocus, !0), window.removeEventListener("resize", this.onResize, !0), window.removeEventListener("submit", this.onSubmit, !0), window.removeEventListener("change", this.onChange, !0), this.interval && clearInterval(this.interval), this.resizeTimeout && clearTimeout(this.resizeTimeout);
  }
}
const Aa = [
  "application/json",
  "application/xml",
  "application/x-www-form-urlencoded",
  "text/"
], to = (e) => {
  const t = e == null ? void 0 : e.toLowerCase();
  return t && Aa.some((r) => t.startsWith(r));
}, lt = (e) => ({
  type: "text",
  text: e
}), Na = (e) => ({
  type: "file",
  name: e.name,
  size: e.size,
  mimeType: e.type
}), qa = (e) => {
  const t = {
    type: "form-data",
    data: {}
  };
  return e.forEach((r, n) => {
    r instanceof File ? t.data[n] = Na(r) : typeof r == "string" && (t.data[n] = r);
  }), t;
}, Oa = (e) => {
  const t = {
    type: "url-search-params",
    data: {}
  };
  return e.forEach((r, n) => {
    t.data[n] = r;
  }), t;
}, za = (e) => ({
  type: "blob",
  size: e.size,
  mimeType: e.type
}), Ha = (e) => ({
  type: "array-buffer",
  byteLength: e.byteLength
}), ro = (e) => {
  try {
    return typeof e == "string" ? lt(e) : e instanceof FormData ? qa(e) : e instanceof URLSearchParams ? Oa(e) : e instanceof Blob ? za(e) : e instanceof ArrayBuffer ? Ha(e) : {
      type: "unknown"
    };
  } catch {
    return;
  }
}, Fa = async (e) => {
  const t = e.headers.get("content-type");
  if (to(t)) {
    const n = await e.text();
    return { body: lt(n), raw: n };
  }
  const r = await e.blob();
  return {
    body: { type: "other", size: r.size },
    raw: r
  };
}, Zr = (e) => {
  const t = {};
  return e == null || e.forEach((r, n) => {
    t[n] = r;
  }), t;
}, Ba = () => {
  const e = window.Request;
  class t extends e {
    constructor(o, i) {
      super(o, i);
      D(this, "args");
      this.args = {
        init: i || {}
      }, o instanceof t && (this.args.init = { ...o.args.init, ...this.args.init }), this.clone = function() {
        return new t(o, i);
      };
    }
  }
  return window.Request = t, () => {
    window.Request = e;
  };
}, Va = (e) => {
  const t = window.fetch;
  return window.fetch = (r, n) => {
    const o = r instanceof Request ? r.clone() : new Request(r, n);
    let i;
    try {
      i = ro(o.args.init.body);
    } catch (u) {
      e.recordInternalError(u);
    }
    const s = {
      id: $(),
      type: "request",
      initiator: "fetch",
      time: Date.now(),
      url: o.url,
      startTime: performance.now(),
      request: {
        method: o.method,
        headers: Zr(o.headers),
        body: i
      },
      extra: {
        request: {
          body: o.args.init.body ?? void 0
        }
      }
    };
    return t(r, n).then(async (u) => {
      try {
        const c = u.clone();
        s.response = {
          status: c.status,
          headers: Zr(c.headers)
        }, s.endTime = performance.now();
        const { body: l, raw: a } = await Fa(c);
        s.response = {
          ...s.response,
          body: l
        }, s.extra.response = {
          body: a
        };
      } catch (c) {
        e.recordInternalError(c);
      }
      return e.recordEvent(s), u;
    }).catch((u) => {
      try {
        s.endTime = performance.now(), s.error = Xt(u), u instanceof Error && (s.extra.error = u);
      } catch (c) {
        e.recordInternalError(c);
      }
      throw e.recordEvent(s), u;
    });
  }, () => {
    window.fetch = t;
  };
}, Ua = (e) => {
  if (typeof e != "object" || e === null)
    return !1;
  const t = Object.getPrototypeOf(e);
  return t === Object.prototype || t === null;
}, ja = async (e, t) => {
  if (typeof e == "string")
    return lt(e);
  if (Ua(e))
    return lt(JSON.stringify(e));
  if (to(t) && e instanceof Blob) {
    const r = await e.text();
    return lt(r);
  } else {
    if (e instanceof Blob)
      return {
        type: "other",
        size: e.size
      };
    if (e instanceof ArrayBuffer)
      return {
        type: "other",
        size: e.byteLength
      };
  }
  return {
    type: "other"
  };
}, Ka = (e) => {
  const t = XMLHttpRequest.prototype.open;
  return XMLHttpRequest.prototype.open = function(r, n, o = !0, i, s) {
    const u = this, c = {
      method: r,
      headers: {}
    }, l = {
      status: 0,
      headers: {}
    }, a = {
      request: {
        body: void 0
      }
    }, d = {
      id: $(),
      type: "request",
      time: Date.now(),
      startTime: performance.now(),
      initiator: "xhr",
      url: n.toString(),
      request: c,
      response: l,
      extra: a
    }, f = u.setRequestHeader.bind(u);
    u.setRequestHeader = (p, m) => (c.headers[p.toLowerCase()] = m, f(p, m));
    const v = u.send.bind(u);
    u.send = (p) => (p && (a.request.body = p, c.body = ro(p)), v(p)), u.addEventListener("readystatechange", async () => {
      if (u.readyState !== u.DONE)
        return;
      if (d.endTime = performance.now(), l.status = u.status, u.getAllResponseHeaders().trim().split(/[\r\n]+/).forEach((g) => {
        var x;
        const h = g.split(": "), _ = (x = h.shift()) == null ? void 0 : x.toLowerCase(), y = h.join(": ");
        _ && (l.headers[_] = y);
      }), u.response)
        try {
          a.response = {
            body: u.response
          }, l.body = await ja(
            u.response,
            l.headers["content-type"]
          );
        } catch (g) {
          e.recordInternalError(g);
        }
      e.recordEvent(d);
    }), t.call(u, r, n, o, i, s);
  }, () => {
    XMLHttpRequest.prototype.open = t;
  };
};
class Ya {
  constructor(t) {
    D(this, "observer", null);
    D(this, "unsetters", []);
    D(this, "handleResourceTiming", (t) => {
      t instanceof PerformanceResourceTiming && this.controller.recordEvent({
        id: $(),
        type: "resource",
        time: Math.round(performance.timeOrigin + t.startTime),
        url: t.name,
        initiator: t.initiatorType,
        startTime: t.startTime,
        endTime: t.responseEnd,
        transferSize: t.transferSize,
        encodedBodySize: t.encodedBodySize,
        decodedBodySize: t.decodedBodySize
      });
    });
    this.controller = t;
  }
  start() {
    performance.getEntriesByType("resource").forEach(this.handleResourceTiming), this.unsetters = [], this.unsetters.push(Ba()), this.unsetters.push(Va(this.controller)), this.unsetters.push(Ka(this.controller)), this.observer = new PerformanceObserver((t) => {
      t.getEntries().forEach((r) => {
        r instanceof PerformanceResourceTiming && r.initiatorType !== "fetch" && r.initiatorType !== "xmlhttprequest" && this.handleResourceTiming(r);
      });
    }), this.observer.observe({ entryTypes: ["resource"] });
  }
  stop() {
    var t;
    this.unsetters.forEach((r) => r()), (t = this.observer) == null || t.disconnect();
  }
}
const fe = "$REDACTED$", no = (e) => e.error !== null || !e.response || e.response.status >= 400, Qr = {
  all: () => !0,
  none: () => !1,
  "on-error-only": ({ event: e }) => no(e)
}, $r = {
  all: ({ key: e, value: t }) => [e, t],
  "key-only": ({ key: e }) => [e, fe],
  none: () => [fe, fe],
  "on-error-only": ({ event: e, key: t, value: r }) => no(e) ? [t, r] : [fe, fe]
}, Wa = {
  auto: ({ name: e }) => e,
  "tag-only": ({ element: e }) => e.tagName.toLowerCase()
}, Xa = {
  all: ({ key: e, value: t }) => [e, t],
  "key-only": ({ key: e }) => [e, fe]
}, Ga = {
  all: () => !0,
  none: () => !1
}, Ja = ({
  requestBody: e,
  responseBody: t,
  requestHeader: r,
  responseHeader: n,
  elementName: o,
  elementAttribute: i,
  inputValue: s
} = {}) => ({
  responseBody: typeof t == "function" ? t : Qr[t ?? "all"],
  requestBody: typeof e == "function" ? e : Qr[e ?? "all"],
  requestHeader: typeof r == "function" ? r : $r[r ?? "all"],
  responseHeader: typeof n == "function" ? n : $r[n ?? "all"],
  elementName: typeof o == "function" ? o : Wa[o ?? "auto"],
  elementAttribute: typeof i == "function" ? i : Xa[i ?? "all"],
  inputValue: typeof s == "function" ? s : Ga[s ?? "all"]
}), Za = (e) => ({
  error: e.extra.error,
  url: e.url,
  request: {
    headers: e.request.headers,
    method: e.request.method,
    body: e.extra.request.body
  },
  response: e.response && e.extra.response ? {
    headers: e.response.headers,
    body: e.extra.response.body,
    status: e.response.status
  } : void 0
}), en = (e, t, r) => Object.fromEntries(
  Object.entries(t).map(([n, o]) => n === "authorization" || String(o).toLowerCase().startsWith("bearer") ? [n, fe] : e({ key: n, value: o, event: r }))
), tn = (e, t, r) => {
  if (t !== void 0)
    return e({ event: r }) ? t : fe;
}, Qa = (e, t) => {
  const r = Za(e);
  return {
    ...e,
    request: {
      ...e.request,
      body: tn(
        t.requestBody,
        e.request.body,
        r
      ),
      headers: en(
        t.requestHeader,
        e.request.headers,
        r
      )
    },
    response: e.response ? {
      ...e.response,
      body: tn(
        t.responseBody,
        e.response.body,
        r
      ),
      headers: en(
        t.responseHeader,
        e.response.headers,
        r
      )
    } : void 0
  };
}, $a = (e, t, r) => Object.fromEntries(
  Object.entries(t).map(
    ([n, o]) => e({ key: n, value: o, element: r })
  )
), el = (e, t, r) => r.getAttribute("type") === "password" ? fe : e({ element: r, value: t }) ? t : fe, tl = (e, t) => {
  const r = {
    ...e,
    element: {
      ...e.element,
      name: t.elementName({
        element: e.extra.target,
        name: e.element.name
      }),
      attributes: $a(
        t.elementAttribute,
        e.element.attributes,
        e.extra.target
      )
    }
  };
  return r.type === "change" && (r.value = el(
    t.inputValue,
    r.value,
    e.extra.target
  )), r;
}, rl = (e, t) => {
  switch (e.type) {
    case "request":
      return Qa(e, t);
    case "click":
    case "change":
    case "focus":
    case "submit":
      return tl(e, t);
    default:
      return e;
  }
};
class il extends Un {
  constructor({ privacyOptions: r } = {}) {
    super();
    D(this, "recorders", []);
    D(this, "privacyFunctions");
    D(this, "recordEvent", (r) => {
      const n = rl(r, this.privacyFunctions);
      if ("extra" in n) {
        const { extra: o, ...i } = n;
        this.emit("event", i);
      } else
        this.emit("event", n);
    });
    D(this, "recordInternalError", (r) => {
      r instanceof Error && this.emit("internal-error", Xt(r));
    });
    this.privacyFunctions = Ja(r), this.recorders = [
      new Ya(this),
      new Ra(this),
      new La(this)
    ];
  }
  start() {
    try {
      this.recorders.forEach((r) => {
        r.start();
      });
    } catch (r) {
      ya(`capture.dev encountered an issue: ${r}`);
    }
  }
  stop() {
    this.recorders.forEach((r) => {
      r.stop();
    });
  }
}
export {
  ol as CaptureClient,
  Un as EventEmitter,
  il as EventRecorderController
};
