import { cx } from "@emotion/css";
import type React from "react";

import { Skeleton } from "../skeleton/skeleton.js";

import * as styles from "./button.styles";

export interface ButtonSkeletonProps {
  size?: "xs" | "sm" | "md" | "lg";
  iconOnly?: boolean;
  isRound?: boolean;
  shouldFillContainer?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const ButtonSkeleton = ({
  iconOnly,
  isRound,
  shouldFillContainer,
  size = "lg",
  className,
  children,
}: ButtonSkeletonProps) => {
  return (
    <Skeleton
      className={cx(className, styles.skeleton, styles.button, styles[size], {
        [styles.isRound]: isRound,
        [styles.iconOnly]: iconOnly,
        [styles.fillContainer]: shouldFillContainer,
      })}
    >
      <div className={styles.content}>{children}</div>
    </Skeleton>
  );
};
