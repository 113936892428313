import { cx } from "@emotion/css";
import type React from "react";

import { Skeleton } from "./skeleton.js";
import * as styles from "./text.skeleton.styles";

interface SkeletonTextProps {
  width?: string;
  children?: React.ReactNode;
  className?: string;
}

export const TextSkeleton = ({
  width,
  children,
  className,
}: SkeletonTextProps) => {
  return (
    <Skeleton className={cx(className, styles.root)} style={{ width }}>
      {children}
    </Skeleton>
  );
};
