import { getDisplayMedia } from "./get-display-media.js";
export class BrowserScreenshotTaker {
    events = [];
    canCaptureArea = () => {
        return navigator.userAgent.toLowerCase().includes("chrome");
    };
    takeScreenshot = async () => {
        const stream = await getDisplayMedia();
        try {
            const video = document.createElement("video");
            video.srcObject = stream;
            video.autoplay = true;
            await new Promise((resolve, reject) => {
                video.onloadeddata = resolve;
                video.onerror = reject;
            });
            const videoTrackSettings = video.srcObject?.getTracks()[0].getSettings();
            // Create a canvas with the video's size and draw the video frame on it
            const canvas = document.createElement("canvas");
            const width = videoTrackSettings?.width ?? 0;
            const height = videoTrackSettings?.height ?? 0;
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx?.drawImage(video, 0, 0);
            const image = canvas.toDataURL("image/png", 100);
            stream.getTracks().forEach((track) => track.stop());
            return {
                tabWidth: window.innerWidth,
                tabHeight: window.innerHeight,
                dataUrl: image,
                events: this.events,
            };
        }
        catch (error) {
            stream.getTracks().forEach((track) => track.stop());
            throw error;
        }
    };
    logEvent(event) {
        this.events.push(event);
        // Only store the last 5 minutes of events
        const maxTime = Date.now() - 60 * 1000 * 5;
        let eventsToTrim = 0;
        for (let i = 0; i < this.events.length; i++) {
            if (this.events[i].time < maxTime) {
                eventsToTrim += 1;
            }
            else {
                break;
            }
        }
        if (eventsToTrim > 0) {
            this.events = this.events.slice(eventsToTrim);
        }
    }
}
