import { forwardRef } from "react";

import type { UseButtonProps } from "./use-button.js";
import { useButton } from "./use-button.js";

export type ButtonProps = UseButtonProps<
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onClick">
>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return <button {...useButton(props)} ref={ref} />;
  }
);

Button.displayName = "Button";
