import { Container } from "@components/container/container.js";
import { PageHeaderSkeleton } from "@components/page-layout/page-header.skeleton.js";

import * as styles from "./page-layout.styles";

interface FormPageLayoutProps {
  actions?: React.ReactNode;
  title: string;
  children?: React.ReactNode;
}

export const PageLayoutSkeleton = ({
  actions,

  title,
  children,
}: FormPageLayoutProps) => {
  return (
    <>
      <Container className={styles.header} hasPadding>
        <PageHeaderSkeleton title={title} actions={actions} />
      </Container>
      <Container className={styles.content}>{children}</Container>
    </>
  );
};
