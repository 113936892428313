import { z } from "zod";

import { FormOption } from "./form-types.js";

export const LinearIntegrationSettings = z.object({
  connectionId: z.string(),
  team: FormOption,
  project: FormOption.nullable(),
  workflowStatus: FormOption.nullable(),
  labels: z.array(FormOption).nullable(),
  priority: z.string().nullable(),
});
export type LinearIntegrationSettings = z.infer<
  typeof LinearIntegrationSettings
>;

export const SlackIntegrationSettings = z.object({
  connectionId: z.string(),
  notificationChannel: FormOption.nullable(),
});
export type SlackIntegrationSettings = z.infer<typeof SlackIntegrationSettings>;

export const TrelloIntegrationSettings = z.object({
  connectionId: z.string(),
  board: FormOption.nullable(),
  list: FormOption.nullable(),
});
export type TrelloIntegrationSettings = z.infer<
  typeof TrelloIntegrationSettings
>;

export const IntegrationSettingsMap = z.object({
  linear: LinearIntegrationSettings.nullable(),
  slack: SlackIntegrationSettings.nullable(),
  trello: TrelloIntegrationSettings.nullable(),
});
export type IntegrationSettingsMap = z.infer<typeof IntegrationSettingsMap>;
