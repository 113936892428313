import { z } from "zod";

export const FormOption = z.object({
  label: z.string(),
  value: z.string(),
  groupName: z.string().optional(),
});
export type FormOption = z.infer<typeof FormOption>;

export const AsyncSelectOutput = z.object({
  cursor: z.string().optional(),
  items: z.array(FormOption),
});
export type AsyncSelectOutput = z.infer<typeof AsyncSelectOutput>;
