import type React from "react";

import type { IconType } from "../../utils/types.js";
import { Badge } from "../badge/badge.js";

import * as styles from "./message-card.styles";

interface MessageCardProps {
  isRichIcon?: boolean;
  Icon?: IconType;
  title?: string;
  description?: string;
  action?: React.ReactNode;
}

export const MessageCard = ({
  Icon,
  title,
  description,
  action,
  isRichIcon = false,
}: MessageCardProps) => {
  return (
    <div className={styles.root}>
      {Icon ? (
        isRichIcon ? (
          <Badge size="xl" className={styles.icon}>
            <Icon />
          </Badge>
        ) : (
          <Icon className={styles.icon} size="32" />
        )
      ) : null}
      <hgroup>
        <h2>{title}</h2>
        {description ? <p>{description}</p> : null}
      </hgroup>
      {action ? <div className={styles.action}>{action}</div> : null}
    </div>
  );
};
