import { EventEmitter } from "@capture/client";
import { getDisplayMedia } from "./get-display-media.js";
export class BrowserScreenRecorder extends EventEmitter {
    isRecording = false;
    mediaRecorder = null;
    recordedChunks = [];
    displayStream = null;
    startTime = 0;
    videoStream = null;
    events = [];
    onRecordingStopped = () => {
        this.stopVideoStream();
        if (this.isRecording) {
            const recording = new Blob(this.recordedChunks, { type: "video/webm" });
            const objectUrl = URL.createObjectURL(recording);
            this.emit("complete", {
                objectUrl,
                blob: recording,
                fileType: "webm",
                startTime: this.startTime,
                endTime: Date.now(),
                events: this.events,
            });
        }
        this.displayStream?.getTracks().forEach((track) => track.stop());
        if (this.mediaRecorder) {
            this.mediaRecorder.removeEventListener("dataavailable", this.onDataAvailable);
            this.mediaRecorder.removeEventListener("stop", this.onRecordingStopped);
        }
        this.isRecording = false;
        this.displayStream = null;
        this.mediaRecorder = null;
        this.recordedChunks = [];
        this.events = [];
    };
    onDataAvailable = (event) => {
        this.recordedChunks.push(event.data);
    };
    abortRecording() {
        this.isRecording = false;
        this.mediaRecorder?.stop();
    }
    async getMediaDevices() {
        // Will prompt the user for permissions if not already granted
        const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
        });
        stream.getTracks().forEach((track) => track.stop());
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices
            .filter((device) => device.kind === "audioinput" || device.kind === "videoinput")
            .map((device) => ({
            label: device.label,
            deviceId: device.deviceId,
            kind: device.kind === "audioinput" ? "audioinput" : "videoinput",
        }));
    }
    async startRecording(opts) {
        this.events = [];
        try {
            this.displayStream = await getDisplayMedia();
        }
        catch (error) {
            this.stopVideoStream();
            throw error;
        }
        if (opts?.audioDeviceId) {
            const audioStream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: { exact: opts.audioDeviceId } },
            });
            const audioTrack = audioStream.getAudioTracks()[0];
            this.displayStream.addTrack(audioTrack);
        }
        this.mediaRecorder = new MediaRecorder(this.displayStream);
        this.mediaRecorder.addEventListener("dataavailable", this.onDataAvailable);
        this.mediaRecorder.addEventListener("stop", this.onRecordingStopped);
        this.displayStream.getVideoTracks().forEach((track) => {
            track.addEventListener("ended", () => {
                this.mediaRecorder?.stop();
            });
        });
        this.startTime = Date.now();
        this.isRecording = true;
        this.mediaRecorder.start(1000);
        return this.startTime;
    }
    async stopRecording() {
        if (this.mediaRecorder) {
            this.mediaRecorder.stop();
        }
    }
    async createVideoElement(container, deviceId) {
        const video = document.createElement("video");
        video.controls = false;
        video.style.objectFit = "cover";
        container.appendChild(video);
        const hasStream = this.videoStream
            ?.getVideoTracks()
            .some((track) => track.enabled);
        if (!hasStream) {
            this.videoStream = await navigator.mediaDevices.getUserMedia({
                video: { deviceId: { exact: deviceId } },
            });
        }
        video.srcObject = this.videoStream;
        await video.play();
    }
    async stopVideoStream() {
        this.videoStream?.getTracks().forEach((track) => track.stop());
    }
    async getRecordingState() {
        if (!this.mediaRecorder) {
            return {
                isRecording: false,
            };
        }
        return {
            isRecording: true,
            isRecordingTab: true,
            startTime: this.startTime,
        };
    }
    logEvent(event) {
        if (this.isRecording) {
            this.events.push(event);
        }
    }
}
