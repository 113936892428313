import { CaptureClient, EventRecorderController, } from "@capture/client";
import { BrowserScreenRecorder } from "./browser-screen-recorder.js";
import { BrowserScreenshotTaker } from "./browser-screenshot-taker.js";
const loadFont = async (url, weight) => {
    const font = new FontFace("Figtree", `url(${url})`, {
        weight,
    });
    try {
        await font.load();
        document.fonts.add(font);
    }
    catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};
export const createBrowserClient = (options = {}) => {
    const isDisabled = typeof options.isDisabled === "function"
        ? options.isDisabled()
        : options.isDisabled;
    if (!isDisabled) {
        loadFont("https://cdn.capture.dev/capture-js/browser/figtree-latin-400-normal.woff", "normal");
        loadFont("https://cdn.capture.dev/capture-js/browser/figtree-latin-700-normal.woff", "bold");
    }
    const screenshotTaker = new BrowserScreenshotTaker();
    const screenRecorder = new BrowserScreenRecorder();
    const { privacyOptions, ...clientOptions } = options;
    const controller = new EventRecorderController({ privacyOptions });
    controller.on("event", (event) => {
        screenshotTaker.logEvent(event);
        screenRecorder.logEvent(event);
    });
    controller.start();
    return new CaptureClient({
        position: "bottom-right",
        source: "browser",
        screenshotTaker,
        screenRecorder,
        widgetUrl: `https://app.capture.dev/widget/preview?isFullScreen=true`,
        handleKeyboardShortcut(event) {
            return event.key === "/" && (event.ctrlKey || event.metaKey);
        },
        ...clientOptions,
    });
};
