import { createBrowserClient } from "@capture/browser";

import { config } from "../config.js";

export const captureClient = createBrowserClient({
  widgetUrl: `${config.WEBAPP_ORIGIN}/widget/preview`,
  captureKey: config.CAPTURE_KEY,
  isDisabled: () => {
    return window.location.pathname.startsWith("/widget");
  },
});
